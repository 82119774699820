import React, {useEffect, useState} from 'react';
import './index.css';

import {PhotoProvider, PhotoSlider} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import Constants from '../../Constants';

import {useWidth} from '../../components/Layout';
import LoadingRoot from '../../components/Layout/LoadingRoot';
import ResponsiveImage from '../../components/elements/ResponsiveImage';
import Slider from '../../components/elements/Slider';
import SliderSteps from '../../components/elements/Slider/SliderSteps';
import FoldingContentContainer from '../../components/elements/FoldingContentContainer';
import TestimonialVideosSection from '../../components/sections/TestimonialVideos';
import IconItemsWrapperSection from '../../components/sections/IconItemsWrapper';
import SocialMediaSection from '../../components/sections/SocialMediaSection';

import IconItemsWrapperContentItemType from '../../types/elements/IconItemsWrapperContentItemType';
import ResponsiveImageSet from '../../types/ResponsiveImageSet';
import FoldingContentItem from '../../types/elements/FoldingContentItem';
import AboutSection3ItemType from '../../types/elements/AboutSection3ItemType';

import Section1Mobile from '../../assets/images/pages/about/section1/mobile.png';
import Section1Tablet from '../../assets/images/pages/about/section1/tablet.png';
import Section1Desktop from '../../assets/images/pages/about/section1/desktop.png';

import Section1SliderIconFirst from '../../assets/images/pages/about/section1/slider/icons/first.png';
import Section1SliderIconSecond from '../../assets/images/pages/about/section1/slider/icons/second.png';

import FirstItemImage from '../../assets/images/pages/home/first.png';
import SecondItemImage from '../../assets/images/pages/home/second.png';

import Section3FirstItemImage from '../../assets/images/pages/about/section3/first.png';
import Section3SecondItemImage from '../../assets/images/pages/about/section3/second.png';
import Section3ThirdItemImage from '../../assets/images/pages/about/section3/third.png';
import Section3FourthItemImage from '../../assets/images/pages/about/section3/fourth.png';

import Section5FirstItemImage from '../../assets/images/pages/about/section5/first.png';
import Section5SecondItemImage from '../../assets/images/pages/about/section5/second.png';
import Section5ThirdItemImage from '../../assets/images/pages/about/section5/third.png';
import Section5FourthItemImage from '../../assets/images/pages/about/section5/fourth.png';
import Section5FifthItemImage from '../../assets/images/pages/about/section5/fifth.png';
import Section5SixthItemImage from '../../assets/images/pages/about/section5/sixth.png';
import Section5SeventhItemImage from '../../assets/images/pages/about/section5/seventh.png';
import Section5EighthItemImage from '../../assets/images/pages/about/section5/eighth.png';

import Section5SearchIcon from '../../assets/images/pages/about/section5/icons/search.png';

import Section6Mobile from '../../assets/images/pages/about/section6/mobile.png';
import Section6Tablet from '../../assets/images/pages/about/section6/tablet.png';
import Section6Desktop from '../../assets/images/pages/about/section6/desktop.png';

import Section6IconFirst from '../../assets/images/pages/about/section6/icons/first.png';
import Section6IconSecond from '../../assets/images/pages/about/section6/icons/second.png';
import Section6IconThird from '../../assets/images/pages/about/section6/icons/third.png';

const section1ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section1Mobile,
        style: {
            height: 'auto',
            width: '90vw',
            marginTop: '2.5rem',
            marginBottom: '2.5rem'
        }
    },
    tablet: {
        src: Section1Mobile,
        style: {
            height: 'auto',
            width: '90vw'
        }
    },
    desktop: {
        src: Section1Tablet,
        style: {
            height: 'fit-content',
            width: '45vw',
            paddingLeft: '5vw'
        }
    },
    huge: {
        src: Section1Desktop,
        style: {
            height: 'fit-content',
            width: '45vw',
            padding: '5vw'
        }
    }
}

const section1SlidersItems: IconItemsWrapperContentItemType[] = [
    {
        title: 'Unsere Vision',
        content: 'Eine Pflegebranche, in der Respekt und Fairness die treibenden Werte sind und der Mensch im Mittelpunkt steht. Der Beruf, der wieder zur Berufung wird, und der Aspekt zu helfen, ist zwar ein entscheidender Grund dabei, aber nicht mehr der einzige.',
        icon: Section1SliderIconFirst
    },
    {
        title: 'Unsere Mission',
        content: 'Modelle und Strukturen etablieren, durch die sowohl das Fachpersonal als auch Einrichtungen nachhaltig voneinander profitieren, weil Umstände beflügelnd und nicht mehr belastend wirken.',
        icon: Section1SliderIconSecond
    }
];

const firstItem: FoldingContentItem = {
    topTitle: 'Für',
    title: 'Alltagshelden',
    content: 'Pflegekarriere ohne Kompromisse? Wir stellen sicher, dass du deine Arbeit mit Herz, Seele und vor allem fairen Bedingungen ausüben kannst!',
    backgroundImage: `url(${FirstItemImage})`,
    buttonTo: '/bewerber'
}
const secondItem: FoldingContentItem = {
    topTitle: 'Für',
    title: 'Einrichtungen',
    content: 'Wo Zufriedenheit des Teams und Qualität der Pflege Hand in Hand gehen: Starten Sie mit uns den Schritt in eine ethischere Zukunft des Pflegewesens.',
    backgroundImage: `url(${SecondItemImage})`,
    buttonTo: '/bewerber'
}

const aboutSection3Items: AboutSection3ItemType[] = [
    {
        title: 'Jenny, 27 Jahre',
        subtitle: 'Gesundheits- und Kranken-pflegerin im Work & Travel',
        image: Section3FirstItemImage,
    },
    {
        title: 'Marco, 26 Jahre',
        subtitle: 'Gesundheits- und Kranken-pflegerin im Work & Travel',
        image: Section3SecondItemImage,
    },
    {
        title: 'Inge, 54 Jahre',
        subtitle: 'MTRA im Work & Travel',
        image: Section3ThirdItemImage,
    },
    {
        title: 'Joachim, 31 Jahre',
        subtitle: 'Gesundheits- und Kranken-pfleger ITS im Work & Travel',
        image: Section3FourthItemImage,
    }
];

const section5Images: string[] = [
    Section5FirstItemImage,
    Section5SecondItemImage,
    Section5ThirdItemImage,
    Section5FourthItemImage,
    Section5FifthItemImage,
    Section5SixthItemImage,
    Section5SeventhItemImage,
    Section5EighthItemImage,
];

const section6ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section6Mobile,
        style: {
            position: 'relative',
            right: -10,
            height: 'auto',
            width: '90vw'
        }
    },
    tablet: {
        src: Section6Mobile,
        style: {
            height: 'auto',
            width: '90vw',
            position: 'relative',
            right: -5,
        }
    },
    desktop: {
        src: Section6Tablet,
        style: {
            height: 'auto',
            width: '45vw',
            position: 'relative',
            right: '-43.5vw',
        }
    },
    huge: {
        src: Section6Desktop,
        style: {
            height: 'auto',
            width: '45vw',
            position: 'relative',
            right: '-47vw',
        }
    }
}

const section6ContentItems: IconItemsWrapperContentItemType[] = [
    {
        title: 'Work & Travel',
        content: 'Unser modernes Arbeitsmodell im Gesundheitswesen: Pflegen und reisen – überregional oder bundesweit. Work & Travel bietet mehr. Mehr Flexibilität. Mehr Sicherheit. Mehr Geld. Mehr Leben.',
        icon: Section6IconFirst
    },
    {
        title: 'Urbanes Personal',
        content: 'In Berlin halten wir auch regionale Jobs bereit, hier kann auch ein hybrides Modell genutzt werden, welches Reisen und regionale Einsätze kombinierbar macht.',
        icon: Section6IconSecond
    },
    {
        title: 'Hybrid',
        content: 'Auch eine Mischform der Beschäftigungsmodelle ist möglich - das Work and Travel System lässt sich mit den regionalen Einsätzen, an deine Bedürfnisse angepasst, kombinieren.',
        icon: Section6IconThird
    }
];

const AboutPage = () => {
    const {width} = useWidth();

    const [loaded, setLoaded] = useState(false);
    const [section1SliderStep, setSection1SliderStep] = useState(1);

    const [gallerySlideVisible, setGallerySliderVisible] = useState(false);
    const [gallerySliderIndex, setGallerySliderIndex] = useState(0);

    if (gallerySlideVisible) {
        Constants.setLoaderEnabled(false)
    }

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, []);

    const renderSection1SliderSteps = () => {
        return (
            <SliderSteps steps={section1SlidersItems.length} activeStep={section1SliderStep}/>
        )
    }

    const renderSection1SliderArrows = () => {
        const childrenCount = section1SlidersItems.length;
        const step = 1;

        return (
            <div id="section1SliderArrows">
                <button
                    className={'slider-navigate-left' + (section1SliderStep > 0 ? '' : ' inactive')}
                    onClick={() => {
                        if (section1SliderStep > 0) {
                            Constants.executeWithoutLoader(() => setSection1SliderStep(section1SliderStep - step))
                        }
                    }}
                    disabled={section1SliderStep < 1}
                />
                <button
                    className={'slider-navigate-right' + (section1SliderStep >= (childrenCount - step) ? ' inactive' : '')}
                    onClick={() => {
                        if (section1SliderStep < (childrenCount - step)) {
                            Constants.executeWithoutLoader(() => setSection1SliderStep(section1SliderStep + step))
                        }
                    }}
                    disabled={section1SliderStep >= (childrenCount - step)}
                />
            </div>
        )
    }

    const renderSection1SliderItem = () => {
        const item = section1SlidersItems[section1SliderStep];

        return (
            <div
                key={'section1_slider_step_' + section1SliderStep}
                className="section1-slider-item"
            >
                <img alt={item.title!} src={item.icon}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <p className="section1-slider-item-title">{item.title!}</p>
                    <p className="section1-slider-item-content" dangerouslySetInnerHTML={{__html: item.content}}/>
                </div>
            </div>
        )
    }

    const renderSection1Slider = () => {
        return (
            <div id="section1Slider">
                {renderSection1SliderItem()}
                <div id="section1SliderNavigation">
                    {renderSection1SliderSteps()}
                    {renderSection1SliderArrows()}
                </div>
            </div>
        )
    }

    const renderSection3Item = (item: AboutSection3ItemType, index: number) => {
        const active = width < Constants.LayoutBreakpoints.desktop;
        return (
            <div className={'section3-item' + (active ? ' active' : '')} key={'section3_item_' + index}>
                <div className="section3-item-image-container">
                    <img alt={item.title} src={item.image}/>
                </div>
                <div className="section3-item-texts">
                    <p className="section3-item-title">{item.title}</p>
                    <p className="section3-item-subtitle">{item.subtitle}</p>
                </div>
            </div>
        )
    }

    const renderSection5ImageItem = (item: string, index: number) => {
        const imageIndex = section5Images.indexOf(item);
        return (
            <div
                key={'section5_image_item_' + index}
                className="section5-image-item"
                onClick={() => {
                    setGallerySliderIndex(section5Images.indexOf(item));
                    setGallerySliderVisible(true);
                }}
                style={width < Constants.LayoutBreakpoints.desktop && [5,6,7].includes(imageIndex) ? {
                    right: '8.5%'
                } : {}}
            >
                <div className="section5-image-item-overlay">
                    <img alt="" src={Section5SearchIcon}/>
                </div>
                <img
                    alt={'Echter Zusammenhalt ' + (index + 1)}
                    src={item}
                    height="auto"
                    width="100%"
                />
            </div>
        );
    }

    const renderSection5Gallery = () => {
        const firstSlide = (
            <div key="gallery_slide_first" className="gallery-slide">
                <div className="gallery-row">
                    {renderSection5ImageItem(section5Images[0], 0)}
                </div>
                <div className="gallery-row">
                    {[1, 2]
                        .map((i) => section5Images[i])
                        .map(renderSection5ImageItem)
                    }
                </div>
            </div>
        );
        const lastSlide = (
            <div key="gallery_slide_last" className="gallery-slide">
                <div className="gallery-row">
                    {[5, 6]
                        .map((i) => section5Images[i])
                        .map(renderSection5ImageItem)
                    }
                </div>
                <div className="gallery-row">
                    {renderSection5ImageItem(section5Images[7], 7)}
                </div>
            </div>
        );
        return width >= Constants.LayoutBreakpoints.tablet
            ? [
                firstSlide,
                (
                    <div key="gallery_slide_second" className="gallery-slide">
                        {[3, 4]
                            .map((i) => section5Images[i])
                            .map(renderSection5ImageItem)
                        }
                    </div>
                ),
                lastSlide
            ]
            : [
                firstSlide,
                (
                    <div key="gallery_slide_second" className="gallery-slide">
                        <div className="gallery-row">
                            {renderSection5ImageItem(section5Images[3], 3)}
                        </div>
                    </div>
                ),
                (
                    <div key="gallery_slide_third" className="gallery-slide">
                        <div className="gallery-row">
                            {renderSection5ImageItem(section5Images[4], 4)}
                        </div>
                    </div>
                ),
                lastSlide
            ];
    }

    return loaded ? (
        <PhotoProvider>
            <PhotoSlider
                images={section5Images.map((item) => ({src: item, key: item}))}
                visible={gallerySlideVisible}
                onClose={() => Constants.executeWithoutLoader(() => setGallerySliderVisible(false), 10, 500)}
                index={gallerySliderIndex}
                onIndexChange={setGallerySliderIndex}
            />
            <div className="pageContent" id="aboutPage">
                <div className="section" id="section1">
                    <ResponsiveImage width={width} imageSet={section1ImageSet} alt="Mittelpunkt Mensch"/>
                    <div className="section1-part">
                        <p className="section-title">Mittelpunkt Mensch</p>
                        <p className="section-subtitle">
                            Bei uns ist <span>Menschlichkeit</span> und <span>Vertrauen</span> im Vordergrund
                        </p>
                        <p className="content-text">
                            Unser Ansatz zielt darauf ab, alte, festgefahrene Strukturen zu sprengen und Raum für eine
                            ethischere Zukunft in der Pflegebranche zu schaffen. Wir wissen, dass Pflegekräfte mehr
                            verdienen als Druck, schlechte Bezahlung und unzumutbare Arbeitsbedingungen.
                            <br/>
                            <br/>
                            Wir wissen auch, dass Einrichtungen verzweifelt kompetentes und engagiertes Fachpersonal
                            benötigen – hier knüpfen wir an und wollen dabei nicht nur vermitteln, sondern eine gänzlich
                            neue Basis für die Branche schaffen.
                        </p>
                        {width >= Constants.LayoutBreakpoints.desktop
                            ? renderSection1Slider()
                            : null
                        }
                    </div>
                </div>
                {width < Constants.LayoutBreakpoints.desktop
                    ? renderSection1Slider()
                    : null
                }
                <div className="section" id="section2">
                    <div className="section2-part">
                        <p className="title">Flexiblies Arbeiten in der Medizin</p>
                        <p className="subtitle">
                            Wir <span>bringen</span> Medizinpersonal & Einrichtungen <span>zusammen</span>
                        </p>
                        <p className="content-text">
                            KOMEDIS fungiert als Bindeglied zwischen Institutionen und Arbeitenden im Gesundheitswesen.
                            Wir
                            überlassen unsere Mitarbeiter dorthin, wo Hilfe gebraucht, geschätzt und fair entlohnt wird.
                        </p>
                    </div>
                    <FoldingContentContainer
                        className="section2-part"
                        width={width}
                        firstItem={firstItem}
                        secondItem={secondItem}
                    />
                </div>
                <div className="full-width" id="section3">
                    <div id="section3-background"/>
                    <div id="section3-texts">
                        <div>
                            <p className="section-title">Alltagshelden mit</p>
                            <p className="section-subtitle"><span>Traumjob</span> bei KOMEDIS</p>
                        </div>
                        <p className="content-text">Lerne echte Menschen aus unserem KOMEDIS-Team kennen und erfahre
                            mehr
                            über sie.</p>
                    </div>
                    <div id="section3-items">
                        {width < Constants.LayoutBreakpoints.desktop ? (
                            <Slider
                                width={width}
                                containerWidth={width > Constants.LayoutBreakpoints.mobile && width < Constants.LayoutBreakpoints.desktop ? width * 0.9 : null}
                                stepPerNavigate={width < Constants.LayoutBreakpoints.tablet ? 1 : 2}
                                scrollToInline="start"
                                scrollToBlock={width < Constants.LayoutBreakpoints.tablet ? 'start' : 'nearest'}
                            >
                                {aboutSection3Items.map(renderSection3Item)}
                            </Slider>
                        ) : aboutSection3Items.map(renderSection3Item)}
                    </div>
                </div>
                <TestimonialVideosSection
                    width={width}
                    title="Was unser Team denkt"
                    subtitle="Von Wünschen zur Realität"
                    contentText="In den Videos berichten Mitarbeitende aus dem KOMEDIS-Kosmos und erzählen von ihren bisher erlebten Erfahrungen und Eindrücken."
                />
                <div className="section" id="section5">
                    <div>
                        <p className="section-title">Das unterscheidet uns</p>
                        <p className="section-subtitle"><span>Echter</span> Zusammenhalt</p>
                    </div>
                    <div id="galleryItems">
                        {width < Constants.LayoutBreakpoints.tablet ? (
                            <Slider width={width} stepPerNavigate={1}>
                                {renderSection5Gallery()}
                            </Slider>
                        ) : renderSection5Gallery()}
                    </div>
                </div>
                <IconItemsWrapperSection
                    width={width}
                    id="section6"
                    title="Innovative Beschäftigungsmodelle"
                    subtitle="Synergie als <span>Mehrwert</span> für Kunden"
                    contentText="Unsere Arbeitsmodelle ermöglichen eine individuelle und flexible Anpassung, sodass private Lebensumstände mit dem beruflichen Leben vereinbar gemacht werden können."
                    imageSet={section6ImageSet}
                    contentItems={section6ContentItems}
                />
                <SocialMediaSection width={width}/>
            </div>
        </PhotoProvider>
    ) : <LoadingRoot isLoading={true} inline={true}/>
}

export default AboutPage
