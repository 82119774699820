import React, {CSSProperties, ReactElement} from 'react';
import './index.css'

export type InputProps = {
    name: string,
    title: string,
    required: boolean,
    id?: string | undefined,
    type?: string | undefined,
    value?: string | readonly string[] | number | undefined,
    className?: string | undefined,
    style?: CSSProperties | undefined,
    containerStyle?: CSSProperties | undefined,
    extraView?: ReactElement | undefined,
    extraProps?: object | undefined
}

const defaultProps: InputProps = {
    name: '',
    title: '',
    required: false,
    id: undefined,
    type: undefined,
    value: undefined,
    className: undefined,
    style: undefined,
    containerStyle: undefined,
    extraView: undefined,
    extraProps: undefined
}

const Input = (props: InputProps) => {
    const {type, name, title, required, id, value, className, style, containerStyle, extraView, extraProps} = props;

    return (
        <div className={'input-container input-' + type + '-container'} style={containerStyle}>
            <input
                type={type}
                name={name}
                title={title}
                id={id}
                value={value}
                required={required}
                placeholder=" "
                className={'input input-' + type + (typeof className === 'undefined' ? '' : ' ' + className)}
                style={style}
                {...(typeof extraProps === 'undefined' ? {} : extraProps)}
            />
            {extraView ?? null}
        </div>
    );
}

Input.defaultProps = defaultProps;

export default Input
