import md5 from 'crypto-js/md5';

const MD5 = (string: string) => {
    return md5(string);
}

const RemoveHTML = (string: string) => {
    return string.replace(/(<([^>]+)>)/gi, '');
}

export default {
    MD5,
    RemoveHTML
}
