import Constants from '../Constants';
import ResponsiveLayout from '../enums/ResponsiveLayout';
import ResponsiveValueSet from '../types/ResponsiveValueSet';

const getLayoutType = (width: number): ResponsiveLayout => {
    return width < Constants.LayoutBreakpoints.tablet
        ? ResponsiveLayout.mobile
        : (
            width < Constants.LayoutBreakpoints.desktop
                ? ResponsiveLayout.tablet
                : (
                    width < Constants.LayoutBreakpoints.huge
                        ? ResponsiveLayout.desktop
                        : ResponsiveLayout.huge
                )
        )
}

const resolveResponsiveValue = (width: number, valueSet: ResponsiveValueSet) => {
    return valueSet[getLayoutType(width)]
}

const ResponsiveUtil = {
    getLayoutType,
    resolveResponsiveValue
}

export default ResponsiveUtil
