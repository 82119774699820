import React from 'react';
import './TestimonialVideoItem.css';
import TestimonialVideoItemType from '../../../types/elements/TestimonialVideoItemType';
import VideoPlayer from '../../elements/VideoPlayer';

const TestimonialVideoItem = (props: {
    testimonialVideoItem: TestimonialVideoItemType
}) => {
    const {testimonialVideoItem} = props;
    return (
        <div className="testimonial-video-item">
            <VideoPlayer video={testimonialVideoItem.video} showBackdrop={false} />
        </div>
    );
}

export default TestimonialVideoItem
