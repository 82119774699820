import React, {useEffect, useState} from 'react';
import './index.css';

import Constants from '../../Constants';
import LoadingRoot from '../../components/Layout/LoadingRoot';

import Select from '../../components/elements/Select';
import API from '../../utils/API';
import BlogCategory from '../../types/entities/BlogCategory';
import SocialMediaSection from '../../components/sections/SocialMediaSection';
import {useWidth} from '../../components/Layout';
import BlogSection from '../../components/sections/Blog';
import {useSearchParams} from 'react-router-dom';

const BlogOverviewPage = () => {
    const {width} = useWidth();

    const [searchParams, setSearchParams] = useSearchParams();

    const [loaded, setLoaded] = useState(false);

    const [categories, setCategories] = useState<BlogCategory[]>([]);
    const [selectedCategorySlug, setSelectedCategorySlug] = useState<string | null>(
        searchParams.has('category') ? searchParams.get('category') : null
    )

    const getCategoryFromSlug = (slug: string): BlogCategory|null => {
        const queryCategory = categories.filter(category => category.slug === slug);
        if (queryCategory.length) {
            return queryCategory.at(0)!
        }
        return null
    }

    useEffect(() => {
        if (!loaded) {
            Constants.setLoading(true)

            API.getBlogCategories()
                .then(newCategories => setCategories(newCategories))
                .catch(e => Constants.logException(e))
                .finally(() => setLoaded(true));

        } else {
            Constants.setLoading(false);
        }
    }, []);

    const updateSearchParams = () => {
        const params = searchParams;
        if (selectedCategorySlug) {
            params.set('category', selectedCategorySlug);
        } else {
            params.delete('category')
        }
        setSearchParams(params, {
            preventScrollReset: true,
        });
    }

    useEffect(() => updateSearchParams(), [
        selectedCategorySlug
    ]);

    return loaded ? (
        <div className="pageContent" id="blogOverviewPage">
            <div className="section" id="categorySelectSection">
                <Select
                    id="categorySelect"
                    placeholder="Kategorie"
                    items={[
                        {
                            title: 'Alle',
                            value: 'all'
                        },
                        ...(
                            categories.map(category => {
                                return {
                                    title: category.title,
                                    value: category.slug
                                };
                            })
                        )
                    ]}
                    selected={selectedCategorySlug && getCategoryFromSlug(selectedCategorySlug) ? {
                        title: getCategoryFromSlug(selectedCategorySlug)!.title,
                        value: getCategoryFromSlug(selectedCategorySlug)!.slug
                    } : null}
                    onSelect={selectedValue => setSelectedCategorySlug(
                        selectedValue === null
                            ? null
                            : selectedValue.value
                    )}
                />
            </div>
            <BlogSection width={width} category={selectedCategorySlug ? getCategoryFromSlug(selectedCategorySlug) : null} showInfoBlock={false}/>
            <SocialMediaSection width={width}/>
        </div>
    ) : <LoadingRoot isLoading={true} inline={true}/>
}

export default BlogOverviewPage
