import React, {useEffect, useState} from 'react';
import './index.css'

import Constants from '../../Constants';
import LoadingRoot from '../../components/Layout/LoadingRoot';
import InformationContainerItem from '../../types/elements/InformationContainerItem';
import ResponsiveImage from '../../components/elements/ResponsiveImage';
import InformationContainer from '../../components/elements/InformationContainer';
import ResponsiveImageSet from '../../types/ResponsiveImageSet';
import FAQSection from '../../components/sections/FAQ/index'

import CareerSection1InformationItemType from '../../types/elements/CareerSection1InformationItemType';

import Section1Mobile from '../../assets/images/pages/career/section1/mobile.png';
import Section1Tablet from '../../assets/images/pages/career/section1/tablet.png';
import Section1Desktop from '../../assets/images/pages/career/section1/desktop.png';
import {useWidth} from '../../components/Layout';
import HomeSection2InformationItemType from '../../types/elements/HomeSection2InformationItemType';
import HomeSection2InformationItem from '../Home/HomeSection2InformationItem';

const section1InfoContainerItem: InformationContainerItem = {
    title: 'Wir suchen Dich!',
    subtitle: 'Unsere aktuellen <span>offenen Stellen</span>',
    content: 'Wir vermitteln durch verschiedenste Fachbereichen des Gesundheitswesens. Klicke einfach auf das Fachgebiet, in dem du tätig bist und bewirb dich direkt bei uns.',
};

const section1ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section1Mobile,
        style: {
            right: 0,
            height: 'auto',
            width: '95vw'
        }
    },
    tablet: {
        src: Section1Mobile,
        style: {
            right: 0,
            height: 'auto',
            width: '95vw'
        }
    },
    desktop: {
        src: Section1Tablet,
        style: {
            right: '-5vw',
            height: 'auto',
            width: '50vw'
        }
    },
    huge: {
        src: Section1Desktop,
        style: {
            right: '-5vw',
            height: 'auto',
            width: '50vw'
        }
    }
}

const section1InformationItems: CareerSection1InformationItemType[] = [
    {
        title: 'Gesundheits- & Krankenpfleger',
        content: 'Aus Leidenschaft zur Pflege - kümmere dich um das Wohl und die gesundheitliche Betreuung der Patienten.',
        to: 'https://jobs.komedis.de/jobs/?search_category=gesundheits-krankenpfleger'
    },
    {
        title: 'Fachkrankenpfleger',
        content: 'Spezialisiert und essenziell - nutze dein Expertenwissen und gestalte die Pflege in deinem Fachgebiet aktiv mit.',
        to: 'https://jobs.komedis.de/jobs/?search_category=fachkrankenpfleger'
    },
    {
        title: 'Gesundheits- & Kinderkrankenpfleger',
        content: 'Für die Kleinsten da sein - begleite Kinder und ihre Familien in ihren medizinischen Belangen.',
        to: 'https://jobs.komedis.de/jobs/?search_category=gesundheits-kinderkrankenpfleger'
    },
    {
        title: 'MTRA | Radiologie',
        content: 'Die entscheidende Hilfe - stelle mittels Bildgebungsverfahren die richtige Diagnose, um eine entsprechende Behandlung zu ermöglichen.',
        to: 'https://jobs.komedis.de/jobs/?search_category=mtra-radiologie'
    },
    {
        title: 'Altenpflege',
        content: 'Mit Respekt und Empathie - gestalte den Alltag von Senioren in einer warmen und liebevollen Umgebung.',
        to: 'https://jobs.komedis.de/jobs/?search_category=altenpflege'
    },
    {
        title: 'Geburtshilfe | Kreißsaal',
        content: 'Einzigartige Momente begleiten - unterstütze die Geburt neuer Leben auf einfühlsame Weise.',
        to: 'https://jobs.komedis.de/jobs/?search_category=geburtshilfe-kreisssaal'
    },
    {
        title: 'Intensivmedizin',
        content: 'Lebensrettung - sei da, wenn es drauf ankommt und setze dein Können ein, um die Genesung von schwerkranken Patienten voranzutreiben.',
        to: 'https://jobs.komedis.de/jobs/?search_category=intensivmedizin'
    },
    {
        title: 'Operationsdienst | OP-Pflege',
        content: 'Präzision im OP-Saal - unterstütze Chirurgen bei Eingriffen und trage dazu bei die bestmöglichen Ergebnisse für Patienten zu erreichen.',
        to: 'https://jobs.komedis.de/jobs/?search_category=operationsdienst-op-pflege'
    }
];

const CareerPage = () => {
    const {width} = useWidth();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            Constants.setLoading(true)
            setLoaded(true);
        } else {
            Constants.setLoading(false)
        }
    }, []);

    const [activeSection1InformationItem, setActiveSection1InformationItem] = useState<HomeSection2InformationItemType | null>(null);

    const renderSection1InformationItems = (item: HomeSection2InformationItemType, index: number) => {
        return (
            <HomeSection2InformationItem
                key={'home_section1_information_item_' + index}
                item={item}
                active={activeSection1InformationItem !== null && activeSection1InformationItem.title === item.title}
                setActive={active => setActiveSection1InformationItem(active ? item : null)}
            />
        );
    }

    return loaded ? (
        <div className="pageContent" id="careerPage">
            <div id="section1">
                <div id="section1InformationContainerWrapper" style={{
                    right: 0,
                    position: 'relative',
                    height: 'fit-content',
                    display: 'flex',
                    flexDirection: width >= Constants.LayoutBreakpoints.tablet ? 'row' : 'column'
                }}>
                    <ResponsiveImage width={width} imageSet={section1ImageSet} alt="section1"/>
                    <InformationContainer item={section1InfoContainerItem} width={width}/>
                </div>
                <div id="section1InformationItems">
                    {section1InformationItems.map(renderSection1InformationItems)}
                </div>
            </div>
            <FAQSection width={width} id="faqSection" />
        </div>
    ) : <LoadingRoot isLoading={true} inline={true} />
}

export default CareerPage
