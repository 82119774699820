import React from 'react';

import HeaderImage from './index';

import Small from '../../../../assets/images/headers/career/small.png'
import Big from '../../../../assets/images/headers/career/big.png'

const CareerHeaderImage = (props: { width: number }) => (
    <HeaderImage width={props.width} small={Small} big={Big}/>
);

export default CareerHeaderImage
