import React from 'react';

import SocialMediaIconProps from '../../../../types/elements/SocialMediaIcon';
import SocialIcon from './SocialIcon';

import TikTokLogo from '../../../../assets/images/elements/nav-bar/social-icons/tiktok.png'
import TikTokColorLogo from '../../../../assets/images/elements/nav-bar/social-icons/tiktok-color.png'
import InstagramLogo from '../../../../assets/images/elements/nav-bar/social-icons/instagram.png'
import InstagramColorLogo from '../../../../assets/images/elements/nav-bar/social-icons/instagram-color.png'
import YouTubeLogo from '../../../../assets/images/elements/nav-bar/social-icons/youtube.png'
import YouTubeColorLogo from '../../../../assets/images/elements/nav-bar/social-icons/youtube-color.png'
import Constants from '../../../../Constants';

const socialMediaLinks: SocialMediaIconProps[] = [
    {
        title: 'TikTok',
        url: Constants.SocialMediaLinks.tiktok,
        image: TikTokLogo,
        colorImage: TikTokColorLogo,
    },
    {
        title: 'Instagram',
        url: Constants.SocialMediaLinks.instagram,
        image: InstagramLogo,
        colorImage: InstagramColorLogo
    },
    {
        title: 'YouTube',
        url: Constants.SocialMediaLinks.youtube,
        image: YouTubeLogo,
        colorImage: YouTubeColorLogo
    },
];

const SocialIcons = (props: {
    color: boolean
}) => {
    const renderSocialIcon = (icon: SocialMediaIconProps, index: number) => {
        return <SocialIcon color={props.color} icon={icon} key={'social_icon_' + index} />
    }
    return (
        <div id="socialIcons">
            <ul>
                {socialMediaLinks.map(renderSocialIcon)}
            </ul>
        </div>
    );
}

export default SocialIcons
