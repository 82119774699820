import React, {useState} from 'react';

import './NewsletterBanner.css'

import Validator from '../../../utils/Validator';

const NewsletterBanner = () => {

    const [submitting, setSubmitting] = useState(false);

    const submitSubscribeForm = () => {
        setSubmitting(true);

        const emailInput = document.querySelector('#newsletterSignupForm input') as HTMLInputElement;
        if (!emailInput) {
            setSubmitting(false);
            return;
        }
        if (!emailInput.value || !Validator.isEmail(emailInput.value)) {
            setSubmitting(false);
            alert('Bitte gib eine gültige E-Mail-Adresse an');
            return false;
        }

        // @todo implement newsletter signup api
    }

    return (
        <div id="newsletterBanner">
            <div id="newsletterTitles">
                <p id="newsletterTitle">Spannende Jobs & Neuste Social-Media-Updates</p>
                <p id="newsletterSubtitle">Unsere aktuellen<br/> Branchen-<span>Insights</span></p>
            </div>
            <div id="newsletterContainer">
                <p id="newsletterContent">
                    Bleibe immer up to date mit den neuesten News und Informationen rund um den KOMEDIS-Kosmos.
                </p>
                <div id="newsletterSignupForm">
                    <input type="email" placeholder="E-Mail-Adresse" required={true}/>
                    <button onClick={() => submitSubscribeForm()} disabled={submitting}/>
                </div>
            </div>
        </div>
    )
}

export default NewsletterBanner;
