import React from 'react';
import './index.css'
import Input, {InputProps} from '../Input';

type InputTextProps = InputProps & {
    placeholder: string | null,
}

const defaultProps: InputTextProps = {
    type: 'text',
    name: '',
    title: '',
    required: false,
    placeholder: null,
    id: undefined,
    className: undefined,
    style: undefined,
    extraProps: {}
}

const InputText = (props: InputTextProps) => {
    const {title, required, placeholder} = props;

    return (
        <Input
            extraView={(
                <div className="placeholder">
                    {placeholder ?? title}{required ? (<span>*</span>) : null}
                </div>
            )}
            {...props}
        />
    );
}

InputText.defaultProps = defaultProps;

export default InputText
