import React from 'react';

const SliderSteps = (props: {
    steps: number,
    activeStep: number
}) => {

    const {steps, activeStep} = props;

    const stepsArray = Array(steps).fill(0);

    const renderStep = (index: number) => {
        const active = activeStep === index;
        return (
            <div className={'slider-step' + (active ? '' : ' inactive')} />
        );
    }

    return (
      <div className="slider-steps">
          {stepsArray.map((_, index) => renderStep(index))}
      </div>
    );
}

export default SliderSteps
