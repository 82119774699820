import React from 'react';

import HeaderImage from './index';

import Small from '../../../../assets/images/headers/about/small.png'
import Big from '../../../../assets/images/headers/about/big.png'

const AboutUsHeaderImage = (props: { width: number }) => (
    <HeaderImage width={props.width} small={Small} big={Big}/>
);

export default AboutUsHeaderImage
