import React, {Children, JSX, ReactNode, useEffect, useRef, useState} from 'react';
import './index.css';
import Constants from '../../../Constants';

const Slider = (props: {
    width: number,
    containerWidth?: number | null,
    stepPerNavigate?: number | undefined,
    children: ReactNode | null
    bottomView?: JSX.Element | null,
    hideIndicator?: boolean | null,
    whiteArrows?: boolean | null,
    scrollToInline?: ScrollLogicalPosition | null,
    scrollToBlock?: ScrollLogicalPosition | null,
    onNavigate?: (currentIndex: number) => void | null,
}) => {
    const {
        width,
        containerWidth,
        stepPerNavigate,
        bottomView,
        hideIndicator,
        whiteArrows,
        scrollToInline,
        scrollToBlock,
        onNavigate,
        children
    } = props;

    const sliderWidth = containerWidth ? containerWidth : width * 0.85;
    const childrenCount = Children.count(children);
    const sliderItemWidth = (sliderWidth / childrenCount);
    const step = stepPerNavigate ? stepPerNavigate : width < Constants.LayoutBreakpoints.tablet
        ? 1
        : Constants.LayoutBreakpoints.desktop >= width
            ? 3
            : 2;

    const sliderItemsRef = useRef<HTMLDivElement | null>(null);

    const [previousDisplayedChildIndex, setPreviousDisplayedChildIndex] = useState(0);
    const [displayedChildIndex, setDisplayedChildIndex] = useState(0);

    useEffect(() => {
        if (displayedChildIndex !== previousDisplayedChildIndex && sliderItemsRef.current) {
            const children = sliderItemsRef.current!.children;
            const item = children.item(displayedChildIndex);
            if (item) {
                if (width >= Constants.LayoutBreakpoints.desktop) {
                    const removeIndex = displayedChildIndex - step;
                    for (let index = 0; index < children.length; index++) {
                        const modifyItem = children.item(index)!;
                        if (index <= removeIndex) {
                            modifyItem.classList.add('hidden');
                        } else {
                            modifyItem.classList.remove('hidden');
                        }
                    }
                } else {
                    const scrollOptions: ScrollIntoViewOptions = {
                        behavior: 'smooth',
                        inline: step === 1 ? 'center' : 'end',
                        block: 'center'
                    };
                    if (scrollToInline) {
                        scrollOptions.inline = scrollToInline;
                    }
                    if (scrollToBlock) {
                        scrollOptions.block = scrollToBlock;
                    }
                    item.scrollIntoView(scrollOptions)
                }
            }
            setPreviousDisplayedChildIndex(displayedChildIndex)

            if (typeof onNavigate === 'function') {
                onNavigate(displayedChildIndex);
            }
        }
    }, [displayedChildIndex]);

    return (
        <div className="slider">
            <div ref={ref => sliderItemsRef.current = ref} className="slider-items">
                {children}
            </div>
            {hideIndicator ? null : (
                <>
                    <div className="slider-line"/>
                    <div className="slider-line-active" style={{
                        width: sliderItemWidth * step,
                        left: displayedChildIndex === 0
                            ? 0
                            : (sliderItemWidth * displayedChildIndex) // / step
                    }}/>
                </>
            )}
            <div className="slider-bottom">
                {bottomView ? (
                    <div className="slider-bottom-view-container">
                        {bottomView}
                    </div>
                ) : null}
                <div className="slider-navigator">
                    <button
                        className={'slider-navigate-left' + (whiteArrows ? ' white' : '') + (displayedChildIndex > 0 ? '' : ' inactive')}
                        onClick={() => {
                            if (displayedChildIndex > 0) {
                                setDisplayedChildIndex(displayedChildIndex - step)
                            }
                        }}
                        disabled={displayedChildIndex < 1}
                    />
                    <button
                        className={'slider-navigate-right' + (whiteArrows ? ' white' : '') + (displayedChildIndex >= (childrenCount - step) ? ' inactive' : '')}
                        onClick={() => {
                            if (displayedChildIndex < (childrenCount - step)) {
                                setDisplayedChildIndex(displayedChildIndex + step)
                            }
                        }}
                        disabled={displayedChildIndex >= (childrenCount - step)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Slider
