import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import * as Sentry from '@sentry/react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ErrorFallback from './screens/ErrorFallback'
import Constants from './Constants';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';
import versionsInfo from './version.json'

Sentry.init({
    dsn: Constants.SENTRY_DSN,
    release: 'komedis-main@' + versionsInfo.version,
    dist: versionsInfo.dist,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: Constants.SENTRY_TRACE_SAMPLE_RATE,
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
)
root.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
            <App/>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
