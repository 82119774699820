import React, {useEffect, useState} from 'react';
import './index.css'

import Constants from '../../Constants';
import API from '../../utils/API';
import {Link, useParams} from 'react-router-dom';
import Notifications from '../../utils/Notifications';
import BlogArticle from '../../types/entities/BlogArticle';
import RedirectFaker from '../../components/elements/RedirectFaker';
import {useWidth} from '../../components/Layout';
import BlogArticleImage from '../../components/Layout/Header/HeaderImage/BlogArticle';
import BlogEmbedType from '../../enums/BlogEmbedType';

import Plyr from 'plyr-react'
import 'plyr-react/plyr.css'
import VideoPlayer from '../../components/elements/VideoPlayer';
import {VideoType} from '../../types/Video';
import ContactItemMobile from '../../assets/images/pages/blog-article/contact-item/man.png';
import BlogSection from '../../components/sections/Blog';
import {FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton} from 'react-share';
import BlogUtil from '../../utils/Blog';
import BlogItem from '../../components/sections/Blog/BlogItem';
import ArrowRightIcon from '../../assets/images/elements/slider/arrow-right.png';

const BlogArticlePage = () => {
    const {slug} = useParams();
    const {width} = useWidth();

    const [loaded, setLoaded] = useState(false);
    const [article, setArticle] = useState<BlogArticle | null>(null);
    const [currentArticles, setCurrentArticles] = useState<BlogArticle[]>([]);

    useEffect(() => {
        if (!slug) {
            window.location.href = '/' + Constants.RoutesMeta.blog.path;
            return;
        }
        Constants.setLoading(true)
        API.loadBlogArticle(slug)
            .then(blogArticle => {
                if (blogArticle) {
                    setArticle(blogArticle);
                    Constants.setHeaderImage(
                        <BlogArticleImage width={width} imageUrl={blogArticle.imageUrl}/>
                    );
                    Constants.setInformationContainerItem({
                        title: blogArticle.category.title,
                        subtitle: blogArticle.title,
                        content: blogArticle.description,
                    });
                    Constants.scrollToTop();
                    Constants.setLoading(false);
                } else {
                    window.location.href = '/' + Constants.RoutesMeta.blog.path;
                    return;
                }
            })
            .catch(e => {
                Constants.setLoading(false);
                Notifications.error('Ein Fehler ist aufgetreten', e)
                setTimeout(() => window.location.href = '/magazin', 2000);
            }).finally(() => {
            setLoaded(true);
        });
    }, [slug]);

    useEffect(() => {
        if (
            currentArticles.length < 1
            || (
                article !== null
                && currentArticles.filter(currentArticle => currentArticle.slug === article.slug).length > 0
            )
        ) {
            API.getBlogArticles(1, null, 3, article)
                .then(response => {
                    if (response.data.blogArticles) {
                        setCurrentArticles(response.data.blogArticles)
                    }
                });
        }
    }, [article, width]);

    const renderEmbedContainer = () => {
        const embedData = article!.embed!;

        let embedView = null
        switch (embedData.type) {
            case BlogEmbedType.audio: {
                embedView = (
                    <Plyr
                        source={{
                            type: 'audio',
                            sources: [{
                                src: embedData.source,
                                type: 'audio/mp3'
                            }]
                        }}
                    />
                );
                break;
            }
            case BlogEmbedType.video: {
                embedView = (
                    <VideoPlayer
                        video={{
                            url: embedData.source,
                            type: VideoType.file,
                            previewImage: embedData.preview!
                        }}
                        showBackdrop={true}
                    />
                );
                break;
            }
            case BlogEmbedType.url: {
                if (embedData.plyr) {
                    embedView = (
                        <Plyr
                            source={{
                                type: 'video',
                                sources: [{
                                    src: embedData.plyr.id,
                                    provider: embedData.plyr.provider as Plyr.Provider
                                }]
                            }}
                        />
                    );
                } else {
                    embedView = (
                        <iframe
                            src={embedData.source}
                            allowFullScreen={true}
                            allow="autoplay"
                        ></iframe>
                    );
                    break;
                }
                break;
            }
            case BlogEmbedType.html: {
                embedView = (
                    <div dangerouslySetInnerHTML={{__html: embedData.source!}}/>
                );
                break;
            }
        }
        return (
            <div id="embedContainer">
                {embedView}
            </div>
        );
    }

    const renderContactItem = () => {
        return (
            <div id="contactItem">
                {width < Constants.LayoutBreakpoints.tablet ? (
                    <div className="contact-item-part" style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <img src={ContactItemMobile} style={{
                            height: 'auto',
                            width: '70vw'
                        }} alt="Die Zusammenarbeit mit KOMEDIS"/>
                    </div>
                ) : null}
                <div id="contact-item-content" className="contact-item-part">
                    <p id="contact-item-title">Die Zusammenarbeit mit KOMEDIS</p>
                    <p id="contact-item-subtitle">Bei <span>Fragen</span> sind wir gerne für dich da.</p>
                    <Link to={'/' + Constants.RoutesMeta.contact.path}>
                        Jetzt kontaktieren
                    </Link>
                </div>
            </div>
        );
    }

    return loaded ?
        article ? (
            <div className="pageContent" id="blogArticlePage">
                <div id="contentSection" className="full-width">
                    <div id="articleContent">
                        <div id="articleInner" dangerouslySetInnerHTML={{
                            __html: article.content
                        }}/>
                        {article.embed ? renderEmbedContainer() : null}
                        {width >= Constants.LayoutBreakpoints.desktop ? renderContactItem() : null}
                    </div>
                    { /*
                 @todo replace with actual ads
                   placeholder:
                    */}
                    {width >= Constants.LayoutBreakpoints.desktop ? (
                        <div id="rightSideContainer">
                            <div id="adContainer">
                                <div id="firstAd"><p>first ad</p></div>
                                <div id="secondAd"><p>second ad</p></div>
                            </div>
                            {currentArticles.length > 0 ? (
                                <div id="currentArticles">
                                    <p className="title">Aktuelle Beliebte Beiträge</p>
                                    {currentArticles.map((currentArticle, index) => {
                                        return (
                                            <BlogItem
                                                showImage={false}
                                                key={'current_blog_article_item_' + index}
                                                article={currentArticle}
                                            />
                                        )
                                    })}
                                </div>
                            ) : null}
                            <div id="sidebarSocialContainer">
                                <p className="title">KOMEDIS AUF SOCIAL MEDIA</p>
                                {Object.values(Constants.SocialMediaItems).map((item, index) => {
                                    return (
                                        <div key={'social_media_item_' + index} className="social-media-item">
                                            <a target="_blank" rel="noreferrer" href={item.url} className="social-media-item-link">
                                                <span>{item.title}</span>
                                                <img alt="arrow" src={ArrowRightIcon} />
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
                {width < Constants.LayoutBreakpoints.desktop ? renderContactItem() : null}
                <div id="articleFooter">
                    {article.author ? (
                        <>
                            <div className="line"/>
                            <div id="authorContainer">
                                <img alt={article.author.name} src={article.author.imageUrl}/>
                                <p>{article.author.name}</p>
                            </div>
                        </>
                    ) : null}
                    <div className="line"/>
                    <div id="shareContainer">
                        <p>Beitrag teilen:</p>
                        <div id="socialShareIcons">
                            <FacebookShareButton
                                url={BlogUtil.getArticleUrl(article)}
                                title={article.title}
                            >
                                <img
                                    height={37.75}
                                    width={37.75}
                                    alt="Share on Facebook"
                                    src={require('../../assets/images/pages/blog-article/social-share/facebook.png')}
                                />
                            </FacebookShareButton>
                            <TwitterShareButton
                                title={article.title}
                                url={BlogUtil.getArticleUrl(article)}
                            >
                                <img
                                    height={37.75}
                                    width={37.75}
                                    alt="Share on X"
                                    src={require('../../assets/images/pages/blog-article/social-share/x.png')}
                                />
                            </TwitterShareButton>
                            <PinterestShareButton
                                title={article.title}
                                description={article.title + ' - ' + Constants.SITE_NAME}
                                media={article.imageUrl}
                                url={BlogUtil.getArticleUrl(article)}
                            >
                                <img
                                    height={37.75}
                                    width={37.75}
                                    alt="Share on Pinterest"
                                    src={require('../../assets/images/pages/blog-article/social-share/pinterest.png')}
                                />
                            </PinterestShareButton>
                            <LinkedinShareButton
                                title={article.title}
                                url={BlogUtil.getArticleUrl(article)}
                            >
                                <img
                                    height={37.75}
                                    width={37.75}
                                    alt="Share on LinkedIn"
                                    src={require('../../assets/images/pages/blog-article/social-share/linkedin.png')}
                                />
                            </LinkedinShareButton>
                        </div>
                    </div>
                    <div className="line"/>
                    <div id="footerButtons">
                        <a
                            className="button button-outline"
                            href={Constants.SITE_URL + '/' + Constants.RoutesMeta.blog.path}
                        >
                            Zurück zur Übersicht
                        </a>
                        {article.nextArticle ? (
                            <a
                                className="button"
                                href={BlogUtil.getArticleUrl(article.nextArticle)}
                            >
                                Nächster Beitrag
                            </a>
                        ) : null}
                    </div>
                </div>
                <BlogSection
                    headerTitle="Ähnliche Beiträge"
                    skipArticle={article}
                    width={width}
                    category={article.category}
                    showInfoBlock={false}
                />
            </div>
        ) : <RedirectFaker to={'/404'}/>
        : null
}

export default BlogArticlePage
