import BlogArticle from '../types/entities/BlogArticle';
import Constants from '../Constants';

const getArticleUrlBySlug = (slug: string): string => {
    return Constants.SITE_URL + '/' + Constants.BlogArticleRouteSlug.replace(
        Constants.BlogArticleRouteSlug.split('/')[1],
        slug
    );
};

const BlogUtil = {
    getArticleUrlBySlug,
    getArticleUrl: (article: BlogArticle): string => {
        return getArticleUrlBySlug(article.slug);
    }
};

export default BlogUtil;
