import React, {useEffect, useState} from 'react';
import './index.css'
import IconItemsWrapperContentItemType from '../../../types/elements/IconItemsWrapperContentItemType';
import ResponsiveUtil from '../../../utils/Responsive';
import Constants from '../../../Constants';

const IconItemsWrapperContentItem = (props: {
    item: IconItemsWrapperContentItemType,
    index: number,
    width: number,
    expanded: boolean,
    setExpanded: (expanded: boolean) => void,
    layoutHasImage: boolean
}) => {
    const {item, index, width, expanded, setExpanded, layoutHasImage} = props;

    const getContainerHeight = (): number => {
        return ResponsiveUtil.resolveResponsiveValue(width, {
            mobile: 212,
            tablet: 212,
            desktop: 245,
            huge: 266
        }) as number
    }

    const getMaxLength = (): number => {
        return ResponsiveUtil.resolveResponsiveValue(width, {
            mobile: 125,
            tablet: 150,
            desktop: 145,
            huge: 175
        }) as number
    }

    const [containerHeight, setContainerHeight] = useState(getContainerHeight());
    const [maxLength, setMaxLength] = useState(getMaxLength())

    useEffect(() => {
        setContainerHeight(getContainerHeight())
        setMaxLength(getMaxLength())
    }, [width]);

    const getContentFontSize = (): number => {
        return ResponsiveUtil.resolveResponsiveValue(width, {
            mobile: 15,
            tablet: 15,
            desktop: 20,
            huge: 20
        }) as number
    }

    return (
        <div className="icon-items-wrapper-content-item" style={item.title ? {
            height: expanded ? '100%' : containerHeight,
            ...(layoutHasImage ? {} : width < Constants.LayoutBreakpoints.desktop
                    ? width < Constants.LayoutBreakpoints.tablet ? {
                        position: 'relative',
                        top: '10rem',
                    } : {
                        position: 'relative',
                        top: '-5rem'
                    }
                    : {}
            )
        } : {
            ...(index === 0
                && width < Constants.LayoutBreakpoints.desktop
                && width > Constants.LayoutBreakpoints.mobile
                    ? {
                        marginLeft: '2.5rem',
                    }
                    : {}
            )
        }}>
            <div className="icon-items-wrapper-content-item-top" style={{
                ...(expanded ? {
                    marginTop: 5
                } : {}),
                ...(item.title ? {} : {
                    alignItems: 'flex-start',
                    margin: 0,
                    padding: 5,
                })
            }}>
                <img src={item.icon} alt={item.title ?? 'icon'}/>
                {item.title ? (
                    <p className="icon-items-wrapper-content-item-title">
                        {item.title}
                    </p>
                ) : (
                    <p className="icon-items-wrapper-content-item-content" style={{
                        fontSize: getContentFontSize(),
                        letterSpacing: 'normal',
                        margin: 0,
                        padding: 0,
                    }} dangerouslySetInnerHTML={{__html: item.content}}/>
                )}
            </div>
            {item.title ? (
                <>
                    <div className="icon-items-wrapper-content-item-bottom-container">
                        <p className="icon-items-wrapper-content-item-content" style={expanded ? {
                            marginTop: '1.5rem'
                        } : {}}
                           dangerouslySetInnerHTML={{__html: expanded ? item.content : (item.content.length > maxLength ? item.content.slice(0, (maxLength - 3)) + '...' : item.content)}}/>
                    </div>
                    <div className="icon-items-wrapper-content-item-button-container">
                        {item.content.length > maxLength ? (
                            <button onClick={() => Constants.executeWithoutLoader(() => setExpanded(!expanded))}>
                                {expanded ? 'Weniger' : 'Mehr'}
                            </button>
                        ) : null}
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default IconItemsWrapperContentItem
