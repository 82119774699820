import React from 'react';
import './index.css'

import EuCertifiedTrustImage from '../../../../assets/images/elements/trusts/eu-certified.png';
import Arbeitgeber2021TrustImage from '../../../../assets/images/elements/trusts/arbeitgeber-2021.png';
import Aap2019TrustImage from '../../../../assets/images/elements/trusts/aap-2019.png';
import Aap2020TrustImage from '../../../../assets/images/elements/trusts/aap-2020.png';
import TrustImage from '../../../../types/elements/TrustImage';

const trustImages: TrustImage[] = [
    {
        alt: 'EU Certified Trust Logo',
        image: EuCertifiedTrustImage,
        style: {
            width: '40%',
            height: 'auto',
        }
    },
    {
        alt: 'Arbeitgeber 2021 Trust Logo',
        image: Arbeitgeber2021TrustImage,
        style: {
            width: '20%',
            height: 'auto',
        }
    },
    {
        alt: 'AAP 2019 Trust Logo',
        image: Aap2019TrustImage,
        style: {
            width: '20%',
            height: 'auto',
        }
    },
    {
        alt: 'AAP 2020 Trust Logo',
        image: Aap2020TrustImage,
        style: {
            width: '20%',
            height: 'auto',
        }
    }
];

const TrustImages = (props: any) => {
    const renderTrustImage = (image: TrustImage, index: number) => {
        return (
            <img
                key={'trust_image_' + index}
                alt={image.alt}
                src={image.image}
                style={image.style}
            />
        )
    }

    return (
        <div {...props} id="trustsContainer">
            <p>Zertifikate und Auszeichnungen</p>
            <div id="trustsImagesContainer">
                {trustImages.map(renderTrustImage)}
            </div>
        </div>
    )
}

export default TrustImages
