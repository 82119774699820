import React from 'react';

import HeaderImage from './index';

import Small from '../../../../assets/images/headers/blog-article/small.png'
import Big from '../../../../assets/images/headers/blog-article/big.png'
import ResponsiveImage from '../../../elements/ResponsiveImage';

import HeaderLeftLogoImage from '../../../../assets/images/pages/blog-article/header-left-logo.png';
import Constants from '../../../../Constants';

type BlogArticleImageProps = {
    width: number,
    imageUrl: string | null
}

const defaultProps: BlogArticleImageProps = {
    width: window.innerWidth,
    imageUrl: null
}

const BlogArticleImage = (props: BlogArticleImageProps) => {
    const {width, imageUrl} = props;

    if (imageUrl) {
        const headerImageSet = {
            mobile: {
                src: imageUrl,
                style: {
                    marginTop: 25,
                    marginLeft: '10vw',
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 150,
                    width: '90vw',
                    height: 'auto',
                }
            },
            tablet: {
                src: imageUrl,
                style: {
                    marginTop: 25,
                    marginLeft: '20vw',
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 150,
                    width: '80vw',
                    height: 'auto',
                }
            },
            desktop: {
                src: imageUrl,
                style: {
                    marginTop: 65,
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 250,
                    width: '55vw',
                    height: 'auto',
                }
            },
            huge: {
                src: imageUrl,
                style: {
                    marginTop: 65,
                    borderTopLeftRadius: 25,
                    borderBottomLeftRadius: 150,
                    width: '55vw',
                    height: 'auto',
                }
            }
        }

        return (
            <div id="blogArticleHeaderImageContainer">
                {width >= Constants.LayoutBreakpoints.desktop ? (
                    <div>
                        <img
                            style={{
                                marginTop: 65,
                                marginLeft: 25,
                                height: 260,
                                width: 'auto',
                            }}
                            src={HeaderLeftLogoImage}
                        />
                    </div>
                ) : null}
                <ResponsiveImage width={width} alt="header" imageSet={headerImageSet}/>
            </div>
        );
    }

    return (
        <HeaderImage
            width={width}
            small={Small}
            big={Big}
        />
    );
};

BlogArticleImage.defaultProps = defaultProps;

export default BlogArticleImage
