import React, {useEffect, useState} from 'react';
import './index.css';

import SelectItem from '../../../types/elements/SelectItem';
import ChevronDown from '../../../assets/images/pages/blog-overview/chevron-down.png';
import Constants from '../../../Constants';

type SelectProps = {
    id: string,
    placeholder: string,
    items: SelectItem[],
    required?: boolean | undefined,
    onSelect: (selectedValue: SelectItem|null) => void;
    selected: SelectItem|null,
};

const defaultProps: SelectProps = {
    id: '',
    placeholder: '',
    items: [],
    required: false,
    onSelect: () => {},
    selected: null
}

const Select = (props: SelectProps) => {
    const {id, placeholder, items, required, selected, onSelect} = props;

    const isRequired = typeof required !== 'undefined' && required;

    const [showItems, setShowItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SelectItem | null>(selected);

    useEffect(() => onSelect(selectedItem), [selectedItem]);

    const renderItem = (item: SelectItem, index: number) => {
        if (!item.title) {
            return null;
        }
        return (
            <div
                key={id + '_select_item_' + index}
                className="select-item"
                onClick={() => {
                    setSelectedItem(item)
                    setShowItems(false);
                }}
            >
                <p>{item.title}</p>
            </div>
        );
    }

    return (
        <div className="select-container">
            <div
                id={id}
                className="select"
                onClick={() => {
                    Constants.executeWithoutLoader(() => setShowItems(!showItems))
                }}
            >
                <p dangerouslySetInnerHTML={{
                    __html: selectedItem ? selectedItem.title : placeholder + (isRequired ? '<span>*</span>' : '')
                }} />
                <img alt="chevron down" src={ChevronDown} style={showItems ? {
                    transform: 'rotate(180deg)'
                } : {}}/>
            </div>
            {showItems ? (
                <div className="select-items">
                    {items.map(renderItem)}
                </div>
            ) : false}
        </div>
    );
}

Select.defaultProps = defaultProps;

export default Select;
