import Video, {VideoType} from '../types/Video';

class VideoEmbed {
    protected video: Video;

    constructor(video: Video) {
        this.video = video;
    }

    public getVideoId(): string|null {
        switch (this.video.type) {
            case VideoType.youtube: {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = this.video.url.match(regExp);
                return (match && match[2].length === 11)
                    ? match[2]
                    : null;
            }
            default: {
                return null;
            }
        }
    }
    public getEmbedUrl(): string {
        switch (this.video.type) {
            case VideoType.youtube: {
                return 'https://www.youtube.com/embed/' + this.getVideoId();
            }
            default: {
                return this.video.url;
            }
        }
    }
}

export default VideoEmbed
