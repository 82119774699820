import React from 'react';
import './SocialMediaItem.css';
import SocialMediaItemProps from '../../../types/elements/SocialMediaItem';

import ArrowRightIcon from '../../../assets/images/elements/slider/arrow-right.png';

const SocialMediaItem = (props: {
    item: SocialMediaItemProps
}) => {
    const {item} = props;
    return (
        <div className="social-media-item">
            <img className="social-media-item-image" src={item.imageUrl} alt={item.title}/>
            <a target="_blank" rel="noreferrer" href={item.url} className="social-media-item-link">
                <span>{item.title}</span>
                <img alt="arrow" src={ArrowRightIcon} />
            </a>
        </div>
    );
}

export default SocialMediaItem
