import React, {FormEventHandler, useEffect, useState} from 'react';
import './index.css'
import Constants from '../../Constants';
import LoadingRoot from '../../components/Layout/LoadingRoot';
import InputText from '../../components/elements/InputText';
import Select from '../../components/elements/Select';
import SelectItem from '../../types/elements/SelectItem';
import InputRadio from '../../components/elements/InputRadio';
import InputTextArea from '../../components/elements/InputTextarea';
import API from '../../utils/API';

const salutionItems: SelectItem[] = [
    {
        title: 'Herr',
        value: 'mr'
    },
    {
        title: 'Frau',
        value: 'mrs'
    },
    {
        title: 'Divers',
        value: 'divers'
    }
];

const titleItems: SelectItem[] = [
    {
        title: 'Bachelor',
        value: 'bachelor',
    },
    {
        title: 'Bakkalaureus',
        value: 'bakkalaureus'
    },
    {
        title: 'Diplom',
        value: 'diplom'
    },
    {
        title: 'Magister',
        value: 'magister'
    },
    {
        title: 'Master',
        value: 'master'
    },
    {
        title: 'Lizenziat',
        value: 'lizenziat',
    },
    {
        title: 'Meisterschüler',
        value: 'meisterschüler'
    },
    {
        title: 'Doktor',
        value: 'doktor'
    },
];

const fachbereichItems: SelectItem[] = [
    {
        title: 'Operationsdienst',
        value: 'operationsdienst'
    },
    {
        title: 'Intensivmedizin',
        value: 'intensivmedizin'
    },
    {
        title: 'Geburtshilfe',
        value: 'geburtshilfe'
    },
    {
        title: 'Altenpflege',
        value: 'altenpflege'
    },
    {
        title: 'MTRA',
        value: 'mtra'
    },
    {
        title: 'Gesundheits- & Kinderkrankenpfleger',
        value: 'gesundheits-und-kinderkrankenpfleger'
    },
    {
        title: 'Fachkrankenpfleger',
        value: 'fachkrankenpfleger'
    },
    {
        title: 'Gesundheits- & Krankenpfleger',
        value: 'gesundheits-und-krankenpfleger'
    }
];

const ContactPage = () => {
    const [loaded, setLoaded] = useState(false);
    const [selectedSalution, setSelectedSalution] = useState<SelectItem | null>(null);
    const [selectedTitle, setSelectedTitle] = useState<SelectItem | null>(null);
    const [selectedType, setSelectedType] = useState('bewerber');
    const [selectedFachbereich, setSelectedFachbereich] = useState<SelectItem | null>(null);
    const [privacyChecked, setPrivacyChecked] = useState(false);

    const [sentForm, setSentForm] = useState(false);

    useEffect(() => {
        if (!loaded) {
            Constants.setLoading(true)
            setLoaded(true);
        } else {
            Constants.setLoading(false)
        }
    }, []);

    const onSubmitContactForm: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        if (!selectedSalution) {
            alert('Wählen Sie eine Anrede aus');
            return false;
        }

        Constants.setLoading(true);

        const formDataObject = new FormData(event.target as HTMLFormElement);

        const formData = {} as any;
        formDataObject.forEach((value, key) => {
            formData[key] = value;
        });

        formData.salution = selectedSalution.title;
        formData.title = selectedTitle ? selectedTitle.title : '';
        formData.fachbereich = selectedFachbereich ? selectedFachbereich.title : '';

        API.sendContactForm(formData)
            .then(result => {
                if (result) {
                    setSentForm(true);
                    Constants.setLoading(false);
                    setTimeout(() => {
                        Constants.scrollToTop(false)
                    }, 500);
                }
            })
            .catch(error => alert(error));

        return false;
    }

    return loaded ? (
        <div className="pageContent" id="contactPage">
            <div className="section" id="contactFormSection">
                {sentForm ? (
                    <div style={{
                        textAlign: 'center'
                    }}>
                        <p style={{
                            textAlign: 'center'
                        }} className="subtitle">Deine Nachricht wurde gesendet!</p>
                    </div>
                ) : (
                    <>
                        <div id="contactFormTexts">
                            <p className="section-title">Kontaktformular</p>
                            <p className="section-subtitle">
                                Du möchtest <span>mehr erfahren</span> oder hast <span>Fragen?</span>
                            </p>
                            <p className="content-text">
                                Dann schicke uns dein Anliegen und wir kümmern uns schnellstmöglich darum, dir
                                weiterzuhelfen.
                            </p>
                            <p id="requiredFieldsTitle"><span>*</span>Pflichtfelder</p>
                        </div>
                        <div id="contactFormContainer">
                            <form id="contactForm" onSubmit={onSubmitContactForm}>
                                <InputText
                                    name="firstName"
                                    title="Vorname"
                                    placeholder="Vorname"
                                    id="firstName"
                                    required={true}
                                />
                                <InputText
                                    name="lastName"
                                    title="Nachname"
                                    placeholder="Nachname"
                                    id="lastName"
                                    required={true}
                                />
                                <Select
                                    id="salution"
                                    placeholder="Anrede"
                                    items={salutionItems}
                                    onSelect={salutionItem => Constants.executeWithoutLoader(() => setSelectedSalution(salutionItem))}
                                    selected={selectedSalution}
                                    required={true}
                                />
                                <Select
                                    id="title"
                                    placeholder="Titel"
                                    items={titleItems}
                                    onSelect={titleItem => Constants.executeWithoutLoader(() => setSelectedTitle(titleItem))}
                                    selected={selectedTitle}
                                />
                                <div
                                    id="typeOptionsContainer"
                                    // @ts-expect-error ignore type hint
                                    onChange={event => Constants.executeWithoutLoader(() => setSelectedType(event.target.value))}
                                >
                                    <InputRadio
                                        name="type"
                                        title="Ich melde mich als <b>Bewerber*in</b>."
                                        required={true}
                                        value="bewerber"
                                        checked={selectedType === 'bewerber'}
                                    />
                                    <InputRadio
                                        name="type"
                                        title="Ich melde mich als <b>Einrichtung</b>."
                                        required={true}
                                        value="einrichtung"
                                        checked={selectedType === 'einrichtung'}
                                    />
                                </div>
                                <InputText
                                    name="myJob"
                                    title="Mein Beruf"
                                    placeholder="Mein Beruf"
                                    id="myJob"
                                    required={false}
                                    containerStyle={{
                                        width: '95%'
                                    }}
                                />
                                <div id="fachbereichContainer">
                                    <p className="helper-text">Folgender Fachbereich <span>interessiert</span> mich:</p>
                                    <Select
                                        id="fachbereich"
                                        placeholder="Fachbereich"
                                        items={fachbereichItems}
                                        onSelect={fachbereichItem => Constants.executeWithoutLoader(() => setSelectedFachbereich(fachbereichItem))}
                                        selected={selectedFachbereich}
                                    />
                                </div>
                                <InputText
                                    type="email"
                                    name="email"
                                    title="E-Mail-Adresse"
                                    placeholder="E-Mail-Adresse"
                                    id="email"
                                    required={true}
                                />
                                <InputText
                                    type="tel"
                                    name="phone"
                                    title="Telefonnummer"
                                    placeholder="Telefonnummer"
                                    id="phone"
                                    required={false}
                                />
                                <div id="endArea">
                                    <InputTextArea
                                        id="messageInput"
                                        className="textarea"
                                        name="message"
                                        title="Ihre Nachricht"
                                        placeholder="Ihre Nachricht"
                                        required={true}
                                    />
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <div onClick={() => setPrivacyChecked(!privacyChecked)}>
                                            <InputRadio
                                                name="privacyCheck"
                                                title="Hiermit erkläre ich mich mit der <b>Datenschutzerklärung</b> einverstanden"
                                                required={true}
                                                checked={privacyChecked}
                                            />
                                        </div>
                                        <button
                                            className="button"
                                            type="submit"
                                        >
                                            Unverbindlich absenden
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </div>
        </div>
    ) : <LoadingRoot isLoading={true} inline={true}/>
}

export default ContactPage
