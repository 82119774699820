import React, {useEffect, useState} from 'react';
import './App.css';
import 'react-notifications-component/dist/theme.css'
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Layout from './components/Layout';
import ErrorFallback from './screens/ErrorFallback';
import LoadingRoot from './components/Layout/LoadingRoot';
import Routes from './Routes';

const App = () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResizeWindow = () => {
            setWidth(window.innerWidth)
        }
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener('resize', handleResizeWindow)
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener('resize', handleResizeWindow)
        }
    }, [])

    const getBaseUrl = (dropQuery: false) => {
        const baseUrl = location.pathname
        return dropQuery ? baseUrl.replace('?' + location.search, '') : baseUrl
    }

    const globals = {
        getBaseUrl,
        getWindowWidth: () => width,
    }

    Object.keys(globals).map(globalKey => {
        // @ts-expect-error ignore type hint
        globalThis[globalKey] = globals[globalKey]
    })

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout
                width={width}
            />,
            children: Routes.Routes,
            errorElement: <ErrorFallback />,
        },
    ])

    return (
        <RouterProvider router={router} fallbackElement={<LoadingRoot isLoading={true}/>}/>
    )
}

export default App;
