import React from 'react';

import ResponsiveImage from '../../../elements/ResponsiveImage';

import Logo from '../../../../assets/images/logo.png'

const defaultLogo = {
    src: Logo,
    style: {
        width: 'auto',
        height: '100%',
        maxHeight: '50px'
    }
};

const logoImageSet = {
    mobile: defaultLogo,
    tablet: defaultLogo,
    desktop: defaultLogo,
    huge: {
        src: Logo,
        style: {
            maxWidth: 354,
            width: '15vw',
            height: 'auto',
        }
    }
};

const NavBarLogo = (props: { width: number }) => {
    const {width} = props;
    return (
        <ResponsiveImage width={width} alt="logo" imageSet={logoImageSet}/>
    )
}

export default NavBarLogo
