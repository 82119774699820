import React from 'react';
import './index.css'
import Constants from '../../../Constants';
import LoadingRoot from '../../Layout/LoadingRoot';
import Slider from '../../elements/Slider';
import SocialMediaItem from './SocialMediaItem';
import SocialMediaItemProps from '../../../types/elements/SocialMediaItem';

const socials = Constants.SocialMediaItems;

const SocialMediaSection = (props: {
    width: number
}) => {

    const {width} = props;

    const renderSocialMediaItem = (item: SocialMediaItemProps, index: number) => {
        return (
            <SocialMediaItem key={'social_media_item_' + index} item={item}/>
        );
    }

    return (
        <div id="socialMediaSection">
            <div id="socialMediaSectionInfoItems">
                <div className="social-media-section-info-item" style={{
                    width: width < Constants.LayoutBreakpoints.tablet
                        ? '100%'
                        : '45%'
                }}>
                    <p id="socialMediaSectionTitle">KOMEDIS auf Social Media</p>
                    <p id="socialMediaSectionSubtitle">Werde jetzt <span>ein Teil</span> unserer Community</p>
                </div>
                <div id="socialMediaSectionInfoItemCTA" className="social-media-section-info-item" style={{
                    width: width < Constants.LayoutBreakpoints.tablet
                        ? '100%'
                        : '55%'
                }}>
                    <p id="socialMediaSectionCTATitle">
                        Die neusten Beiträge und wichtige Informationen zu Berufen, Rechten und vielem mehr aus dem Gesundheitswesen. Informiere dich jetzt - denn KOMEDIS steht für deine berufliche Zukunft mit Rat und Tat an deiner Seite!
                    </p>
                </div>
            </div>
            <div id="socialMediaItemsContainer">
                {width < Constants.LayoutBreakpoints.desktop ? (
                    socials.length > 0
                        ? (
                            <Slider width={width} stepPerNavigate={width < Constants.LayoutBreakpoints.tablet ? 1 : 2}>
                                {socials.map(renderSocialMediaItem)}
                            </Slider>
                        )
                        : <LoadingRoot isLoading={true} inline={true}/>
                ) : (
                    socials.length > 0
                        ? socials.map(renderSocialMediaItem)
                        : <LoadingRoot isLoading={true} inline={true}/>
                )}
            </div>
        </div>
    )
}

export default SocialMediaSection;
