import React from 'react';
import './index.css'
import Constants from '../../../Constants';
import Slider from '../../elements/Slider';
import TestimonialVideoItem from './TestimonialVideoItem';
import {VideoType} from '../../../types/Video';

import TestimonialVideoItemType from '../../../types/elements/TestimonialVideoItemType';

import TestimonialVideoPreviewFirst from '../../../assets/images/pages/applicants/testimonial-videos/first.png';
import TestimonialVideoPreviewSecond from '../../../assets/images/pages/applicants/testimonial-videos/second.png';
import TestimonialVideoPreviewThird from '../../../assets/images/pages/applicants/testimonial-videos/third.png';
import TestimonialVideoPreviewFourth from '../../../assets/images/pages/applicants/testimonial-videos/fourth.png';

const testimonialVideoItems: TestimonialVideoItemType[] = [
    {
        video: {
            type: VideoType.youtube,
            url: 'https://youtu.be/7k0cGP0gbY4',
            previewImage: TestimonialVideoPreviewFirst
        }
    },
    {
        video: {
            type: VideoType.youtube,
            url: 'https://youtu.be/-zm8EArIHBA',
            previewImage: TestimonialVideoPreviewSecond
        }
    },
    {
        video: {
            type: VideoType.youtube,
            url: 'https://youtu.be/2TR4Qb38VCA',
            previewImage: TestimonialVideoPreviewThird
        }
    },
    {
        video: {
            type: VideoType.youtube,
            url: 'https://youtu.be/CIDsvGXcoKk',
            previewImage: TestimonialVideoPreviewFourth
        }
    }
];

const TestimonialVideosSection = (props: {
    width: number,
    title: string,
    subtitle: string,
    contentText?: string | null
}) => {

    const {width, title, subtitle, contentText} = props;

    const renderTestimonialVideoItem = (article: TestimonialVideoItemType, index: number) => {
        return (
            <TestimonialVideoItem key={'testimonial_video_item_' + index} testimonialVideoItem={article}/>
        );
    }

    return (
        <div id="testimonialVideosSection" className="section">
            <div id="testimonialVideosSectionInfoItems">
                <div className="testimonial-videos-section-info-item">
                    <p className="section-title">{title}</p>
                    <p className="section-subtitle" dangerouslySetInnerHTML={{__html: subtitle}}/>
                    {contentText ? (
                        <p className="content-text" dangerouslySetInnerHTML={{__html: contentText}}/>
                    ) : null}
                </div>
            </div>
            <div id="testimonialVideoItemsContainer">
                <Slider
                    width={width}
                    stepPerNavigate={width < Constants.LayoutBreakpoints.desktop ? 1 : undefined}
                    bottomView={(
                        <p id="testimonialVideosSectionCTATitle">Das sagen unsere KOMEDIS Kollegen.</p>
                    )}
                    hideIndicator={true}
                >
                    {testimonialVideoItems.map(renderTestimonialVideoItem)}
                </Slider>
            </div>
        </div>
    )
}

export default TestimonialVideosSection;
