import React from 'react';
import './index.css'
import {InputProps} from '../Input';

type InputTextAreaProps = InputProps & {
    placeholder: string | null,
}

const defaultProps: InputTextAreaProps = {
    type: 'text-area',
    name: '',
    title: '',
    required: false,
    placeholder: null,
    id: undefined,
    className: undefined,
    style: undefined,
    extraProps: {}
}

const InputTextArea = (props: InputTextAreaProps) => {
    const {type, name, title, placeholder, required, id, value, className, style, containerStyle, extraProps} = props;

    return (
        <div className={'input-container input-' + type + '-container'} style={containerStyle}>
            <textarea
                name={name}
                title={title}
                id={id}
                value={value}
                required={required}
                placeholder=" "
                className={'input input-' + type + (typeof className === 'undefined' ? '' : ' ' + className)}
                style={style}
                {...(typeof extraProps === 'undefined' ? {} : extraProps)}
            />
            <div className="placeholder">
                {placeholder ?? title}{required ? (<span>*</span>) : null}
            </div>
        </div>
    );
}

InputTextArea.defaultProps = defaultProps;

export default InputTextArea
