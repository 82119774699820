import React from 'react';
import './index.css'
import InformationContainerItem from '../../../types/elements/InformationContainerItem';
import {Link} from 'react-router-dom';

const InformationContainer = (props: {
    item: InformationContainerItem,
    width: number
    id?: string
}) => {
    const {item, id} = props;
    return (
        <div id={id} className="informationContainer">
            <h1>{item.title}</h1>
            <h2 dangerouslySetInnerHTML={{__html: item.subtitle.replace('\n', '<br/>')}} />
            <p>{item.content}</p>
            {item.button || item.buttonOutline ? (
                <div id="informationContainerButtonsWrapper">
                    {item.button ? (
                        <Link
                            id="informationContainerButtonContainer"
                            dangerouslySetInnerHTML={{__html: item.button}}
                            to={item.buttonTo!}
                        />
                    ) : null}
                    {item.buttonOutline ? (
                        <Link
                            id="informationContainerOutlineButtonContainer"
                            dangerouslySetInnerHTML={{__html: item.buttonOutline}}
                            to={item.buttonOutlineTo!}
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}

export default InformationContainer
