import React from 'react';

import HeaderImage from './index';

import Small from '../../../../assets/images/headers/customers/small.png'
import Big from '../../../../assets/images/headers/customers/big.png'

const CustomersHeaderImage = (props: { width: number }) => (
    <HeaderImage width={props.width} small={Small} big={Big} />
);

export default CustomersHeaderImage
