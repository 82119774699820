import React from 'react';
import SocialMediaIconProps from '../../../../types/elements/SocialMediaIcon';

const SocialIcon = (props: {
    color: boolean,
    icon: SocialMediaIconProps
}) => {
    const {url, title, image, colorImage} = props.icon;
    return (
        <li>
            <a target="_blank" rel="noreferrer" href={url} title={title}>
                <img src={props.color ? colorImage : image} alt={title + ' icon'}/>
            </a>
        </li>
    )
}

export default SocialIcon
