import React, {useEffect, useState} from 'react';
import './index.css';

import {Link} from 'react-router-dom';

import Constants from '../../Constants';

import TestimonialVideosSection from '../../components/sections/TestimonialVideos';

import LoadingRoot from '../../components/Layout/LoadingRoot';
import {useWidth} from '../../components/Layout';
import VideoPlayer from '../../components/elements/VideoPlayer';
import ResponsiveImage from '../../components/elements/ResponsiveImage';
import IconItemsWrapperSection from '../../components/sections/IconItemsWrapper';
import FAQSection from '../../components/sections/FAQ';

import Video, {VideoType} from '../../types/Video';
import ResponsiveImageSet from '../../types/ResponsiveImageSet';

import IconItemsWrapperContentItemType from '../../types/elements/IconItemsWrapperContentItemType';

import Section2Mobile from '../../assets/images/pages/applicants/section2/mobile.png';
import Section2Tablet from '../../assets/images/pages/applicants/section2/tablet.png';
import Section2Desktop from '../../assets/images/pages/applicants/section2/desktop.png';

import Section3Mobile from '../../assets/images/pages/applicants/section3/alltagshelden.png';

import Section4IconFirst from '../../assets/images/pages/applicants/section4/icons/first.png';
import Section4IconSecond from '../../assets/images/pages/applicants/section4/icons/second.png';
import Section4IconThird from '../../assets/images/pages/applicants/section4/icons/third.png';

import Section4Mobile from '../../assets/images/pages/applicants/section4/mobile.png';
import Section4Tablet from '../../assets/images/pages/applicants/section4/tablet.png';
import Section4Desktop from '../../assets/images/pages/applicants/section4/desktop.png';

import Section1VideoPreviewImage from '../../assets/images/pages/applicants/section1/video-preview.png';

import Section6Mobile from '../../assets/images/pages/applicants/section6/mobile.png';
import Section6Tablet from '../../assets/images/pages/applicants/section6/tablet.png';
import Section6Desktop from '../../assets/images/pages/applicants/section6/desktop.png';

import Section6IconTrain from '../../assets/images/pages/applicants/section6/icons/train.png';
import Section6IconTrainSelected from '../../assets/images/pages/applicants/section6/icons/train-selected.png';
import Section6IconLocation from '../../assets/images/pages/applicants/section6/icons/location.png';
import Section6IconLocationSelected from '../../assets/images/pages/applicants/section6/icons/location-selected.png';

import ApplicantsSection6ContentItemType from '../../types/elements/ApplicantsSection6ContentItemType';

// import Section7VideoPreviewImage from '../../assets/images/pages/applicants/section7/video-preview.png';

import Section8Mobile from '../../assets/images/pages/applicants/section8/woman.png';

const section1Video: Video = {
    type: VideoType.youtube,
    url: 'https://www.youtube.com/watch?v=vDKSrMtpDNo', // Constants.API_URL + '/assets/tmp.mp4'
    previewImage: Section1VideoPreviewImage
}

const section2ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section2Mobile,
        style: {
            height: 'fit-content',
            width: '90vw'
        }
    },
    tablet: {
        src: Section2Mobile,
        style: {
            height: 'fit-content',
            width: '90vw'
        }
    },
    desktop: {
        src: Section2Tablet,
        style: {
            height: 'fit-content',
            width: '45vw',
            marginBottom: 100
        }
    },
    huge: {
        src: Section2Desktop,
        style: {
            height: 'fit-content',
            width: '45vw',
            padding: '5vw'
        }
    }
}

const section4ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section4Mobile,
        style: {
            position: 'relative',
            right: -10,
            height: 'auto',
            width: '90vw'
        }
    },
    tablet: {
        src: Section4Mobile,
        style: {
            height: 'auto',
            width: '90vw',
            position: 'relative',
            right: -5,
        }
    },
    desktop: {
        src: Section4Tablet,
        style: {
            height: 'auto',
            width: '45vw',
            position: 'relative',
            right: '-43.5vw',
        }
    },
    huge: {
        src: Section4Desktop,
        style: {
            height: 'auto',
            width: '45vw',
            position: 'relative',
            right: '-47vw',
        }
    }
}

const section4ContentItems: IconItemsWrapperContentItemType[] = [
    {
        title: 'Faires Gehalt',
        content: 'Bei KOMEDIS bekommst du Spitzenkonditionen, die bis zu 130% mehr Nettoeinkommen garantieren. Das bietet finanzielle Freiheit und Sicherheit und gibt Raum für ein selbstbestimmtes Leben. Durch attraktive Zuschläge und direkte Vergütung von Mehrarbeit motivieren wir unsere Mitarbeiter, sich einzubringen und ihre Talente zu nutzen.',
        icon: Section4IconFirst
    },
    {
        title: 'Nachhaltige Entwicklung',
        content: 'In Gesundheitsberufen ist Wissen und Aktualität ein wichtiger Faktor. Deshalb fördern wir unsere Teams auf verschiedenen Ebenen und garantieren Zugang zum Wissenscampus mit jederzeit verfügbaren Content rund um die Pflege und Medizin. Wir unterstützen Fachliteratur und Weiterbildungen bis zu 100% und motivieren unsere Mitarbeiter, sich stetig weiterzuentwickeln.',
        icon: Section4IconSecond
    },
    {
        title: 'Einzigartige Einblicke',
        content: 'Als HealthCare Recruiting Experten liegt uns deine Zufriedenheit am Herzen. Wir wollen, dass du deine Berufung nicht nur ausübst, sondern sie auch leidenschaftlich leben kannst. Mit abwechslungsreichen Einsätzen in verschiedenen Einrichtungen bekommst du Einblicke in unterschiedlichste Fachbereiche. Du sammelst wertvolle Erfahrungen und erweiterst dein Netzwerk in der Gesundheitsbranche. Durch die Zusammenarbeit mit anderen engagierten Pflegekräften und Ärzten wirst du Teil einer Community, die sich für Qualität in der Patientenversorgung einsetzt.',
        icon: Section4IconThird
    }
];

const section6ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section6Mobile,
        style: {
            height: 'auto',
            width: '90vw'
        }
    },
    tablet: {
        src: Section6Mobile,
        style: {
            height: 'auto',
            width: '100%'
        }
    },
    desktop: {
        src: Section6Tablet,
        style: {
            height: 'auto',
            width: '100%'
        }
    },
    huge: {
        src: Section6Desktop,
        style: {
            height: 'auto',
            width: '100%'
        }
    }
}

const section6ContentItems: ApplicantsSection6ContentItemType[] = [
    {
        title: 'Work & Travel',
        content: 'Unser modernes Arbeitsmodell im Gesundheitswesen: Pflegen und reisen – überregional oder bundesweit. Work & Travel bietet mehr. Mehr Flexibilität. Mehr Sicherheit. Mehr Geld. Mehr Leben.',
        icon: Section6IconTrain,
        selectedIcon: Section6IconTrainSelected
    },
    {
        title: 'Urbane Einsätze',
        content: 'In Berlin halten wir auch regionale Jobs bereit, hier kann auch ein hybrides Modell genutzt werden, welches Reisen und regionale Einsätze kombinierbar macht.',
        icon: Section6IconLocation,
        selectedIcon: Section6IconLocationSelected
    }
];

/* const section7Video: Video = {
    type: VideoType.youtube,
    url: 'https://www.youtube.com/watch?v=Bnk7lLgEVU8&t=52s', // Constants.API_URL + '/assets/tmp.mp4'
    previewImage: Section7VideoPreviewImage
} */

const ApplicantsPage = () => {
    const {width} = useWidth();

    const [loaded, setLoaded] = useState(false);
    const [section6SelectedItem, setSection6SelectedItem] = useState(section6ContentItems[0]);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, []);

    const renderSection6ContentItemsWrapper = () => {
        return (
            <div id="section6ContentItemsWrapper">
                <div id="section6ContentItems">
                    {section6ContentItems.map((item, index) => {
                        const isSelected = item.title === section6SelectedItem.title;
                        return (
                            <div
                                key={'section6_content_item_' + index}
                                className={'section6-content-item' + (isSelected ? ' selected' : '')}
                                onClick={() => setSection6SelectedItem(item)}
                            >
                                <div className="section6-content-item-content">
                                    <img src={isSelected ? item.selectedIcon : item.icon} alt={item.title + ' icon'}/>
                                    <p>{item.title}</p>
                                </div>
                                {isSelected ? (
                                    <div id="section6ContentItemTriangle"></div>
                                ) : null}
                            </div>
                        )
                    })}
                </div>
                <p id="section6ContentText">{section6SelectedItem.content}</p>
            </div>
        )
    }

    return loaded ? (
        <div className="pageContent" id="applicantsPage">
            <div className="section video-section" id="videoSection1">
                <VideoPlayer video={section1Video} showBackdrop={true}/>
            </div>
            <div className="section" id="section2">
                <ResponsiveImage width={width} imageSet={section2ImageSet} alt="section 2"/>
                <div className="section2-part">
                    <p className="title">Modernes Arbeitsleben durch moderne Methoden</p>
                    <p className="subtitle">
                        Wir <span>entlasten & verbessern</span> gemeinsam Pflege und Medizin
                    </p>
                    <p className="content-text">
                        Wir wollen eine Basis schaffen, in der die moralische Überzeugung, helfen und pflegen zu wollen,
                        nicht mehr die einzige Motivation ist. Eine Basis, in der der Sinn wieder mehr gesehen werden
                        kann, weil Strukturen und Umstände nicht belastend, sondern beflügelnd wirken.
                    </p>
                </div>
            </div>
            <div id="section3">
                <div id="section3-content" className="section3-part">
                    <p id="section3-title">Pflege ohne Kompromisse</p>
                    <p id="section3-subtitle">Werde bei KOMEDIS zum <span>zufriedenen Alltagshelden</span></p>
                    <Link to={'/' + Constants.RoutesMeta.contact.path}>
                        <svg fill="#47C2D0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                        </svg>
                        In 10 Sek. bewerben
                    </Link>
                </div>
                {width < Constants.LayoutBreakpoints.tablet ? (
                    <div className="section3-part">
                        <img src={Section3Mobile} style={{
                            height: 'auto',
                            width: '70vw'
                        }} alt="Alltagshelden"/>
                    </div>
                ) : null}
            </div>
            <IconItemsWrapperSection
                width={width}
                id="section4"
                title="In unserem Fokus"
                subtitle="Du <span>als Mensch</span> und Mitarbeiter"
                contentText="Es wird höchste Zeit für dich, mehr Anerkennung in der Pflege zu erhalten, begleitet von einer verbesserten Entlohnung und einem fesselnden Arbeitsumfeld."
                imageSet={section4ImageSet}
                contentItems={section4ContentItems}
            />
            <TestimonialVideosSection
                width={width}
                title="Unsere Kollegen"
                subtitle="Echte Geschichten von <span>echten Menschen</span>"
            />
            <div className="section" id="section6">
                <div id="section6Main">
                    <div id="section6ImageWrapper">
                        <ResponsiveImage width={width} imageSet={section6ImageSet} alt="section 6"/>
                    </div>
                    <div id="section6ContentWrapper">
                        <div id="section6ContentTexts">
                            <p className="title">Gesundheits- und Sozialwesen</p>
                            <p className="subtitle">
                                <span>Innovative</span> {width >= Constants.LayoutBreakpoints.desktop ? 'Beschäftigungsmodelle' : 'Beschäftigungs-\nmodelle'}
                            </p>
                            <p className="content-text">
                                Unsere Arbeitsmodelle ermöglichen eine flexible Anpassung, sodass private Lebensumstände
                                mit dem beruflichen Leben vereinbar gemacht werden können.
                            </p>
                        </div>
                        {width < Constants.LayoutBreakpoints.tablet || width >= Constants.LayoutBreakpoints.desktop ? renderSection6ContentItemsWrapper() : null}
                    </div>
                </div>
                {width < Constants.LayoutBreakpoints.tablet || width >= Constants.LayoutBreakpoints.desktop ? null : renderSection6ContentItemsWrapper()}
            </div>
            {/* <div className="section video-section" id="videoSection7">
                <VideoPlayer video={section7Video} showBackdrop={true} showPlayButtonBackground={true}/>
            </div> */}
            <div id="section8">
                <div id="section8-content" className="section8-part">
                    <p id="section8-title">Bist du bereit für</p>
                    <p id="section8-subtitle"><span>Ausreichend Zeit</span> in deinem Leben</p>
                    <Link to={'/' + Constants.RoutesMeta.contact.path}>
                        <svg fill="#47C2D0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                        </svg>
                        In 10 Sek. bewerben
                    </Link>
                </div>
                {width < Constants.LayoutBreakpoints.tablet ? (
                    <div className="section8-part">
                        <img src={Section8Mobile} style={{
                            height: 'auto',
                            width: '70vw'
                        }} alt="Alltagshelden"/>
                    </div>
                ) : null}
            </div>
            <FAQSection width={width} id="section9"/>
        </div>
    ) : <LoadingRoot isLoading={true} inline={true}/>
}

export default ApplicantsPage;
