import React from 'react';
import './HomeSection2InformationItem.css'

import ArrowRight from '../../assets/images/pages/home/section2/arrow-right.png';
import ArrowRightWhite from '../../assets/images/pages/home/section2/arrow-right-white.png';

import HomeSection2InformationItemType from '../../types/elements/HomeSection2InformationItemType';

const HomeSection2InformationItem = (props: {
    item: HomeSection2InformationItemType,
    active: boolean,
    setActive: (active: boolean) => void
}) => {
    const {item, active, setActive} = props;

    return (
        <div
            className="section2-information-item"
            style={
                active ? {
                    color: '#fff',
                    backgroundColor: '#47C2D0'
                } : {}
            }
            onClick={() => {
                setActive(true)
                window.location.href = item.to;
            }}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: -5
            }}>
                <p className="section2-information-item-title">
                    {item.title}
                </p>
                <img src={active ? ArrowRightWhite : ArrowRight} alt="arrow right"/>
            </div>
            <p className="section2-information-item-content">
                {item.content}
            </p>
        </div>
    );
}

export default HomeSection2InformationItem
