import React, {useState} from 'react';
import './index.css'
import FoldingContentItem from '../../../types/elements/FoldingContentItem';
import FoldingContentItemElement from './FoldingContentItemElement';
import Constants from '../../../Constants';

const FoldingContentContainer = (props: {
    width: number,
    firstItem: FoldingContentItem,
    secondItem: FoldingContentItem,
    className?: string
}) => {
    const {width, firstItem, secondItem, className} = props;

    const [unfoldedItem, setUnfoldedItem] = useState(firstItem);

    return (
        <div className={'folding-content-container' + (className ? '' : ' ' + className)}>
            <FoldingContentItemElement
                item={firstItem}
                unfolded={width < Constants.LayoutBreakpoints.desktop || unfoldedItem.title === firstItem.title}
                unfoldItem={item => {
                    if (width >= Constants.LayoutBreakpoints.desktop) {
                        setUnfoldedItem(item)
                    }
                }}
                foldItem={item => {
                    if (width >= Constants.LayoutBreakpoints.desktop) {
                        if (item.title === secondItem.title) {
                            setUnfoldedItem(firstItem)
                        }
                    }
                }}
                buttonTo={firstItem.buttonTo}
                onClick={firstItem.onClick}
            />
            <FoldingContentItemElement
                item={secondItem}
                unfolded={width < Constants.LayoutBreakpoints.desktop || unfoldedItem.title === secondItem.title}
                unfoldItem={item => {
                    if (width >= Constants.LayoutBreakpoints.desktop) {
                        setUnfoldedItem(item)
                    }
                }}
                foldItem={item => {
                    if (width >= Constants.LayoutBreakpoints.desktop) {
                        if (item.title === secondItem.title) {
                            setUnfoldedItem(firstItem)
                        }
                    }
                }}
                buttonTo={secondItem.buttonTo}
                onClick={secondItem.onClick}
            />
        </div>
    )
}

export default FoldingContentContainer
