import React, {useState} from 'react';
import './index.css'

import ResponsiveImage from '../../elements/ResponsiveImage';
import InformationContainer from '../../elements/InformationContainer';
import ResponsiveImageSet from '../../../types/ResponsiveImageSet';
import InformationContainerItem from '../../../types/elements/InformationContainerItem';
import FAQItemType from '../../../types/elements/FAQItemType';

import Section9IconMinus from '../../../assets/images/pages/applicants/section9/icons/minus.png';
import Section9IconPlus from '../../../assets/images/pages/applicants/section9/icons/plus.png';
import Section9Mobile from '../../../assets/images/pages/applicants/section9/mobile.png';
import Section9Tablet from '../../../assets/images/pages/applicants/section9/tablet.png';
import Section9Desktop from '../../../assets/images/pages/applicants/section9/desktop.png';

const infoContainerItem: InformationContainerItem = {
    title: 'Schau dich schlau',
    subtitle: 'Hier bleiben<br/> keine <span>Fragen</span> offen',
    content: 'Hier findest du einige der häufig gestellten Fragen. Solltest du keine passende Antwort auf deine Frage finden, kontaktiere uns gerne und wir helfen dir weiter.',
    button: 'Jetzt kontaktieren',
    buttonTo: '/kontakt'
};

const imageSet: ResponsiveImageSet = {
    mobile: {
        src: Section9Mobile,
        style: {
            left: 0,
            height: 'auto',
            width: '95vw'
        }
    },
    tablet: {
        src: Section9Mobile,
        style: {
            left: 0,
            height: 'auto',
            width: '95vw'
        }
    },
    desktop: {
        src: Section9Tablet,
        style: {
            left: 0,
            height: 'auto',
            width: '40vw'
        }
    },
    huge: {
        src: Section9Desktop,
        style: {
            left: 0,
            height: 'auto',
            width: '45vw'
        }
    }
}

const items: FAQItemType[] = [
    {
        title: 'Welche Buchungsdauer ist für eine Fachkräfte bei KOMEDIS möglich?',
        content: 'Bei KOMEDIS bieten wir flexible Einsatzzeiten für Fachkräfte an. Die Mindestbuchungsdauer beträgt vier Wochen, wobei Einsätze bis zu 18 Monaten und bei Bedarf auch darüber hinaus möglich sind, um den individuellen Anforderungen unserer Kunden gerecht zu werden.'
    },
    {
        title: 'Welche Arten von medizinischen Fachkräften und Qualifikationen kann man bei KOMEDIS buchen?',
        content: 'Unser Portfolio an medizinischem und pflegerischem Fachpersonal ist umfassend. Es reicht von – Gesundheits- und Kranken- sowie Kinderkrankenpflege, OTA,MTR über Hebammen bis hin zu Ärzten. Wir sind bestrebt, eine breite Palette an Qualifikationen anzubieten, um den vielfältigen Bedürfnissen unserer Kunden gerecht zu werden.'
    },
    {
        title: 'Bietet KOMEDIS die Option, Rahmen- oder Master Vendor Verträge abzuschließen?',
        content: 'Ja, KOMEDIS bietet maßgeschneiderte Lösungen, einschließlich der Möglichkeit von Rahmen- oder Master Vendor Verträgen. Abhängig vom Auftragsvolumen bieten wir zudem faire Rabatte und flexible Volumenmodelle an.'
    },
    {
        title: 'Welche direkten Vorteile bietet die Zusammenarbeit mit KOMEDIS?',
        content: 'Einer der Hauptvorteile von KOMEDIS ist unser innovatives Work & Travel Modell. Dadurch sind wir in der Lage, Fachkräfte unterschiedlichster Qualifikationen nahezu überall in Deutschland flexibel einzusetzen und zu skalieren, um den Anforderungen unserer Kunden optimal zu entsprechen.'
    },
    {
        title: 'Verfügt KOMEDIS über eine unbefristete Erlaubnis zur gewerbsmäßigen Arbeitnehmerüberlassung?',
        content: 'Ja, KOMEDIS besitzt eine unbefristete Erlaubnis zur gewerbsmäßigen Arbeitnehmerüberlassung. Wir erfüllen konsequent hohe Standards, was uns zu einem der transparentesten und fairsten Zeitarbeitsunternehmen in Deutschland macht.'
    },
    {
        title: 'Wer ist der Inhaber von KOMEDIS?',
        content: 'KOMEDIS ist ein inhabergeführtes Familienunternehmen im Mittelstand. Wir legen Wert auf nachhaltiges und verantwortungsvolles Unternehmertum.'
    },
    {
        title: 'Wofür steht das Unternehmen KOMEDIS?',
        content: 'KOMEDIS steht für eine wertebasierte Unternehmensphilosophie, die zukunftsorientiertes Denken mit traditionellen und bewährten Unternehmenswerten verbindet. Wir legen großen Wert auf Zuverlässigkeit und langfristige Partnerschaften.'
    }
];

const FAQSection = (props: {
    width: number,
    id: string,
}) => {

    const {width, id} = props;

    const [selectedItems, setSelectedItems] = useState<FAQItemType[]>([]);

    const renderFaqItem = (item: FAQItemType, index: number) => {
        const isSelected = selectedItems.includes(item)
        return (
            <div
                key={'section9_faq_item_' + index}
                className="faq-item"
            >
                <div className="faq-item-content-container">
                    <p className="faq-item-title">{item.title}</p>
                    <button
                        onClick={() => {
                            const itemsWithoutItem = selectedItems.filter(filterItem => filterItem.title !== item.title)
                            if (isSelected) {
                                setSelectedItems(itemsWithoutItem)
                            } else {
                                itemsWithoutItem.push(item)
                                setSelectedItems(itemsWithoutItem)
                            }
                        }}
                    >
                        <img src={isSelected ? Section9IconMinus : Section9IconPlus}
                             alt={isSelected ? 'close' : 'open'}/>
                    </button>
                </div>
                <p className={'faq-item-content' + (isSelected ? ' opened' : '')}>
                    {item.content}
                </p>
            </div>
        )
    }

    return (
        <div className="faq-section" id={id}>
            <div className="faq-information-container-wrapper">
                <ResponsiveImage width={width} imageSet={imageSet} alt="FAQs"/>
                <InformationContainer item={infoContainerItem} width={width}/>
            </div>
            <div className="faq-items">
                {items.map(renderFaqItem)}
            </div>
        </div>
    )
}

export default FAQSection
