import React from 'react';
import {AsyncImage} from 'loadable-image';
import Constants from '../../Constants';
import ResponseImageProps from '../../types/elements/ResponsiveImage';
import Validator from '../../utils/Validator';

const ResponsiveImage = (props: ResponseImageProps) => {
    const {imageSet, width, alt, async} = props
    const breakpoint: ('desktop' | 'tablet' | 'mobile' | 'huge') =
        width > Constants.LayoutBreakpoints.desktop
            ? 'huge'
            : width > Constants.LayoutBreakpoints.tablet
                ? 'desktop'
                : (width > Constants.LayoutBreakpoints.mobile
                        ? 'tablet'
                        : 'mobile'
                )
    const imageSetItem = imageSet[breakpoint];
    return Validator.isEmpty(async)
        ? (
            <img alt={alt} {...imageSetItem} />
        )
        : (
            <AsyncImage alt={alt} {...imageSetItem} />
        )
}

export default ResponsiveImage
