import React from 'react';

import HomePage from './pages/Home';
import ApplicantsPage from './pages/Applicants';
import CustomersPage from './pages/Customers';
import AboutPage from './pages/About';
import CareerPage from './pages/Career';
import BlogOverviewPage from './pages/BlogOverview';
import ContactPage from './pages/Contact';
import BlogArticlePage from './pages/BlogArticle';
import NotFoundPage from './pages/NotFound';

import Route from './types/Route';

import NavigationItemProps from './types/elements/NavigationItem';

import ApplicantsHeaderImage from './components/Layout/Header/HeaderImage/Applicants';
import CustomersHeaderImage from './components/Layout/Header/HeaderImage/Customers';
import AboutUsHeaderImage from './components/Layout/Header/HeaderImage/AboutUs';
import ContactHeaderImage from './components/Layout/Header/HeaderImage/Contact';
import CareerHeaderImage from './components/Layout/Header/HeaderImage/Career';
import BlogHeaderImage from './components/Layout/Header/HeaderImage/Blog';
import BlogArticleImage from './components/Layout/Header/HeaderImage/BlogArticle';
import HomeHeaderImage from './components/Layout/Header/HeaderImage/Home';

import RoutesMeta from './routes.json'

const NavigationItems: NavigationItemProps[] = [
    {
        title: 'Für Bewerber',
        route: {
            ...(RoutesMeta.applicants),
            element: <ApplicantsPage/>,
            headerImage: (width: number) => <ApplicantsHeaderImage width={width} />
        }
    },
    {
        title: 'Für Kunden',
        route: {
            ...(RoutesMeta.customers),
            element: <CustomersPage/>,
            headerImage: (width: number) => <CustomersHeaderImage width={width} />
        },
    },
    {
        title: 'Über uns',
        route: {
            ...(RoutesMeta.about),
            element: <AboutPage/>,
            headerImage: (width: number) => <AboutUsHeaderImage width={width} />
        }
    },
    {
        title: 'Karriere',
        route: {
            ...(RoutesMeta.career),
            element: <CareerPage/>,
            headerImage: (width: number) => <CareerHeaderImage width={width} />
        }
    },
    {
        title: 'Magazin',
        font: 'caveat',
        route: {
            ...(RoutesMeta.blog),
            element: <BlogOverviewPage/>,
            headerImage: (width: number) => <BlogHeaderImage width={width} />
        }
    },
    {
        title: 'Kontakt',
        route: {
            ...(RoutesMeta.contact),
            element: <ContactPage/>,
            headerImage: (width: number) => <ContactHeaderImage width={width} />
        }
    }
];

const BlogArticleRouteSlug = RoutesMeta.blogArticle.path;

const Routes: Route[] = [
    {
        ...(RoutesMeta.home),
        element: <HomePage/>,
        headerImage: (width: number) => <HomeHeaderImage width={width}/>,
    },
    ...(
        NavigationItems
            .filter(navigationItem => typeof navigationItem.route !== 'undefined' && navigationItem.route !== null)
            .map(navigationItem => navigationItem.route!)
    ),
    {
        ...(RoutesMeta.blogArticle),
        element: <BlogArticlePage/>,
        headerImage: (width: number) => <BlogArticleImage width={width}/>
    },
    {
        ...(RoutesMeta.notFound),
        element: <NotFoundPage/>,
    },
];

const getRouteByPath = (path: string): Route|undefined => {
    return Routes.find(route => route.path === path)
}

export default {
    NavigationItems,
    Routes,
    RoutesMeta,
    getRouteByPath,
    BlogArticleRouteSlug,
}
