import React from 'react';
import './BlogItem.css';
import {Link} from 'react-router-dom';
import BlogArticle from '../../../types/entities/BlogArticle';

type BlogItemProps = {
    article: BlogArticle|null,
    showImage?: boolean|undefined
};

const defaultProps: BlogItemProps = {
    article: null,
    showImage: true
}

const BlogItem = (props: BlogItemProps) => {
    const {article, showImage} = props;
    return article === null ? null : (
        <div className="blog-article-item">
            {typeof showImage !== 'undefined' && showImage ? (
                <img src={article.imageUrl} alt={article.title}/>
            ) : null}
            <div className="blog-article-item-info">
                <p className="blog-article-item-title">{article.title}</p>
                <p className="blog-article-item-description">{article.description}</p>
                <Link className="blog-article-item-link" to={'/magazin/' + article.slug}>Weiterlesen</Link>
            </div>
        </div>
    );
}

BlogItem.defaultProps = defaultProps;

export default BlogItem
