import React, {useEffect, useState} from 'react';
import './index.css'

import {Link} from 'react-router-dom';

import Constants from '../../Constants';
import LoadingRoot from '../../components/Layout/LoadingRoot';
import IconItemsWrapperContentItemType from '../../types/elements/IconItemsWrapperContentItemType';
import {useWidth} from '../../components/Layout';
import IconItemsWrapperSection from '../../components/sections/IconItemsWrapper';
import ResponsiveImage from '../../components/elements/ResponsiveImage';
// import OurCustomersSection from './OurCustomers';

import Section1IconFirst from '../../assets/images/pages/customers/section1/icons/first.png';
import Section1IconSecond from '../../assets/images/pages/customers/section1/icons/second.png';
import Section1IconThird from '../../assets/images/pages/customers/section1/icons/third.png';

import ResponsiveImageSet from '../../types/ResponsiveImageSet';
import Section2Mobile from '../../assets/images/pages/customers/section2/mobile.png';
import Section2Tablet from '../../assets/images/pages/customers/section2/tablet.png';
import Section2Desktop from '../../assets/images/pages/customers/section2/desktop.png';

import Section3Mobile from '../../assets/images/pages/customers/section3/gemeinsam.png';

import Section4CheckIcon from '../../assets/images/pages/customers/section4/icons/check.png';
import Section4Mobile from '../../assets/images/pages/customers/section4/mobile.png';
import Section4Tablet from '../../assets/images/pages/customers/section4/tablet.png';
import Section4Desktop from '../../assets/images/pages/customers/section4/desktop.png';

import Section6Mobile from '../../assets/images/pages/customers/section6/mobile.png';
import Section6Tablet from '../../assets/images/pages/customers/section6/tablet.png';
import Section6Desktop from '../../assets/images/pages/customers/section6/desktop.png';
import FAQSection from '../../components/sections/FAQ';

const section1ContentItems: IconItemsWrapperContentItemType[] = [
    {
        title: 'Breit aufgestellt',
        content: 'In unserem KOMEDIS-Team bündeln wir Fachkräfte aus den verschiedensten Bereichen des Gesundheitswesens und der Pflege und haben so immer passende Expert*innen für die entsprechende Situation parat.',
        icon: Section1IconFirst
    },
    {
        title: 'Geographisch flexibel',
        content: 'Unsere Fachkräfte sind nicht nur bereit überregional zu arbeiten, viele wollen es auch. Mit unserem Work & Travel Arbeitsmodell sind wir in der Lage Personal deutschlandweit an Orte zu bringen, wo Hilfe gerade gebraucht wird, während gleichzeitig alle Parteien das bekommen, was sie sich wünschen.',
        icon: Section1IconSecond
    },
    {
        title: 'Schnell und effektiv',
        content: 'Was Sie morgen suchen, haben wir heute schon gefunden. Wir liefern Personalpower, wo und wann sie benötigt wird - auch sofort.',
        icon: Section1IconThird
    }
];

const section2ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section2Mobile,
        style: {
            height: 'auto',
            width: '90vw',
            marginTop: '2.5rem',
            marginBottom: '2.5rem'
        }
    },
    tablet: {
        src: Section2Mobile,
        style: {
            height: 'auto',
            width: '90vw'
        }
    },
    desktop: {
        src: Section2Tablet,
        style: {
            height: 'fit-content',
            width: '45vw',
            paddingLeft: '5vw'
        }
    },
    huge: {
        src: Section2Desktop,
        style: {
            height: 'fit-content',
            width: '45vw',
            padding: '5vw'
        }
    }
}

const section4ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section4Mobile,
        style: {
            position: 'relative',
            height: 'auto',
            width: '90vw',
            left: '-7.5vw',
        }
    },
    tablet: {
        src: Section4Mobile,
        style: {
            height: 'auto',
            width: '90vw',
            position: 'relative',
            left: '-7.5vw',
        }
    },
    desktop: {
        src: Section4Tablet,
        style: {
            height: 'auto',
            width: '45vw',
            position: 'relative',
            left: '-2.5vw',
        }
    },
    huge: {
        src: Section4Desktop,
        style: {
            height: 'auto',
            width: '45vw',
            position: 'relative',
            left: '-12.5vw',
        }
    }
}

const section4ContentItems: IconItemsWrapperContentItemType[] = [
    {
        content: 'Eine Partnerschaft, die auf Vertrauen basiert und in der die Bedürfnisse aller Parteien berücksichtigt und erfüllt werden.',
        icon: Section4CheckIcon
    },
    {
        content: 'Eine allumfassende Personallösungs&shy;strategie, die von der Zeitarbeit über Coaching bis hin zum Recruiting geht.',
        icon: Section4CheckIcon
    },
    {
        content: 'Eine branchenweite Vernetzung, die es sowohl Mitarbeitenden als auch Institutionen ermöglicht, die Vorteile zu nutzen, die sich daraus ableiten lassen.',
        icon: Section4CheckIcon
    }
];

const section6ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section6Mobile,
        style: {
            position: 'relative',
            height: 'auto',
            width: '90vw',
            left: '-7.5vw',
        }
    },
    tablet: {
        src: Section6Mobile,
        style: {
            height: 'auto',
            width: '90vw',
            position: 'relative',
            left: '-7.5vw',
        }
    },
    desktop: {
        src: Section6Tablet,
        style: {
            height: 'auto',
            width: '45vw',
            position: 'relative',
            left: '-6vw',
        }
    },
    huge: {
        src: Section6Desktop,
        style: {
            height: 'auto',
            width: '45vw',
            position: 'relative',
            left: '-7.5vw',
        }
    }
}

const CustomersPage = () => {
    const {width} = useWidth();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, []);

    const getSection3MobileImageStyle = () => {
        const imageWidth = width * 0.8;
        const factor = Math.max(750, imageWidth) / Math.min(750, imageWidth);
        const isBigger = imageWidth > 750;
        return {
            height: (isBigger ? 800 * factor : 800 / factor) / 2,
            width: imageWidth,
        }
    }

    return loaded ? (
        <div className="pageContent" id="customersPage">
            <IconItemsWrapperSection
                id="section1"
                width={width}
                title="Darum lohnt sich eine Partnerschaft"
                subtitle="Entscheidende <span>Vorteile</span> für Sie"
                contentText="Als langjähriger Spezialist für Zeitarbeit im Gesundheitswesen kennen und bieten wir Menschen mit Werten und Persönlichkeit. Unsere Mission ist es qualifiziertes Fach-personal dort einzusetzen, wo es zu den Anforderungen passt."
                contentItems={section1ContentItems}
            />
            <div className="section" id="section2">
                <ResponsiveImage width={width} imageSet={section2ImageSet} alt="Nachhaltige Hilfestellung"/>
                <div className="section2-part">
                    <p className="title">Nachhaltige Hilfestellung</p>
                    <p className="subtitle">
                        Eine <span>Partnerschaft</span>, die mehr bietet als nur Zeitarbeit
                    </p>
                    <p className="content-text">
                        KOMEDIS ist Ihr Partner für ethische Zeitarbeit in der Pflege. Wir haben verstanden, dass die Zufriedenheit Ihres Teams und die Qualität der Pflege eng miteinander verknüpft sind. Unsere Dienstleistungen zielen daher darauf ab, die Arbeitsbedingungen für Pflegekräfte zu verbessern und gleichzeitig dem Fachkräftemangel der Branche entgegenzuwirken, um so eine gesündere Zukunft im Gesundheitswesen zu fördern.
                        <br/>
                        <br/>
                        Wir sind fest davon überzeugt, dass Menschlichkeit im Zentrum der Pflege stehen sollte und wir setzen uns unermüdlich dafür ein, dieses Ziel zu erreichen. Gemeinsam können wir eine bessere Zukunft für die Pflegebranche gestalten und dabei die Menschen in den Mittelpunkt stellen, die sie so dringend benötigen.
                    </p>
                </div>
            </div>
            <div id="section3">
                <div id="section3-content">
                    <p id="section3-title">Veränderungen anstoßen</p>
                    <p id="section3-subtitle"><span>Gemeinsam</span> den ersten Schritt machen!</p>
                    <Link to={'/' + Constants.RoutesMeta.contact.path}>
                        Unverbindlich anfragen
                    </Link>
                </div>
                {width < Constants.LayoutBreakpoints.tablet ? (
                    <div>
                        <img src={Section3Mobile} style={getSection3MobileImageStyle()} alt="Gemeinsam den ersten Schritt machen!"/>
                    </div>
                ) : null}
            </div>
            <IconItemsWrapperSection
                id="section4"
                width={width}
                title="Wo eine Hand die andere wäscht"
                subtitle="<span>Deswegen</span> können Sie auf uns setzen"
                contentText="Unser ganzheitlicher Ansatz stellt sicher, dass Pflege in jeder Hinsicht optimal gewährleistet wird, indem wir folgende Punkte sichern und etablieren:"
                imageSet={section4ImageSet}
                contentItems={section4ContentItems}
            />
            {/* <OurCustomersSection width={width} /> */}
            <div className="section" id="section6">
                <div id="section6-texts">
                    <p className="section-title">Das ist KOMEDIS</p>
                    <p className="section-subtitle">
                        <span>Einblicke</span> in unser Unternehmen
                    </p>
                    <Link className="button" to="#" title="Präsentation herunterladen">
                        Präsentation herunterladen
                    </Link>
                </div>
                <div>
                    <ResponsiveImage width={width} imageSet={section6ImageSet} alt="Das ist KOMEDIS" />
                </div>
            </div>
            <FAQSection width={width} id="section7" />
        </div>
    ) : <LoadingRoot isLoading={true} inline={true}/>
}

export default CustomersPage
