import React from 'react';
import './FoldingContentItemElement.css'
import FoldingContentItem from '../../../types/elements/FoldingContentItem';

import ButtonImage from '../../../assets/images/elements/folding-content-container/button.png';
import {Link} from 'react-router-dom';

const FoldingContentItemElement = (props: {
    item: FoldingContentItem,
    unfolded: boolean,
    unfoldItem: (item: FoldingContentItem) => void,
    foldItem: (item: FoldingContentItem) => void,
    buttonTo: string,
    onClick?: () => void
}) => {
    const {item, unfolded, unfoldItem, foldItem, onClick, buttonTo} = props;
    return (
        <div
            className={'folding-content-item' + (unfolded ? ' unfolded' : '')}
            style={{
                backgroundImage: item.backgroundImage
            }}
            onMouseEnter={() => unfoldItem(item)}
            onMouseLeave={() => foldItem(item)}
        >
            <div>
                <p className="fci-top-title">{item.topTitle}</p>
                <Link
                    to={buttonTo}
                    {...(
                        (onClick
                            ? {onClick: () => onClick()}
                            : {})
                    )}
                >
                    <img src={ButtonImage} alt="button"/>
                </Link>
            </div>
            <p className="fci-title">{item.title}</p>
            <p className="fci-content-text">{item.content}</p>
        </div>
    )
}

export default FoldingContentItemElement
