import React from 'react';

import BurgerMenuIcon from '../../../../assets/images/elements/nav-bar/burger-menu.png'
import Constants from '../../../../Constants';

const BurgerMenuItem = (props: {
    width: number,
    onClick: () => void
}) => {
    const {width, onClick} = props;
    const size = width >= Constants.LayoutBreakpoints.mobile ? 42 : 34;
    return (
        <button
            style={{
                height: size,
                width: size,
                marginRight: (size * 0.25),
                background: 'none',
                border: 0,
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            <img style={{
                height: size,
                width: size
            }} src={BurgerMenuIcon} alt="menu" />
        </button>
    );
}

export default BurgerMenuItem
