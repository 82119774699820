import React, {useState} from 'react';
import './index.css';
import ResponsiveImage from '../../elements/ResponsiveImage';
import Constants from '../../../Constants';
import ResponsiveImageSet from '../../../types/ResponsiveImageSet';
import IconItemsWrapperContentItemType from '../../../types/elements/IconItemsWrapperContentItemType';
import IconItemsWrapperContentItem from '../../elements/IconItemsWrapperContentItem';

const IconItemsWrapperSection = (props: {
    width: number,
    id: string,
    title: string,
    subtitle: string,
    contentText: string,
    contentItems: IconItemsWrapperContentItemType[],
    imageSet?: ResponsiveImageSet | undefined,
}) => {
    const {width, id, title, subtitle, contentText, contentItems, imageSet} = props;

    const layoutHasImage = typeof imageSet !== 'undefined';
    const firstContentItem = contentItems[0]
    const hasTitles = !!firstContentItem.title;

    const [expandedItems, setExpandedItems] = useState<IconItemsWrapperContentItemType[]>([]);

    const renderContentItem = (item: IconItemsWrapperContentItemType, index: number) => {
        return (
            <IconItemsWrapperContentItem
                key={id + '_content_item_' + index}
                index={index}
                item={item}
                width={width}
                layoutHasImage={layoutHasImage}
                expanded={expandedItems.includes(item)}
                setExpanded={expanded => {
                    const itemsWithoutItem = expandedItems.filter(filterItem => filterItem.title !== item.title);
                    if (expanded) {
                        setExpandedItems([...itemsWithoutItem, item])
                    } else {
                        setExpandedItems(itemsWithoutItem)
                    }
                }}
            />
        )
    }

    return (
        <div className="section icon-items-wrapper-section" id={id} style={{
            ...(!layoutHasImage
            && width < Constants.LayoutBreakpoints.tablet
                ? {
                    marginBottom: '10rem'
                }
                : {}),
            ...(layoutHasImage && !hasTitles
                    ? {
                        flexDirection: 'row-reverse'
                    }
                    : {}
            )
        }}>
            <div className="icon-items-wrapper-section-texts" style={layoutHasImage
                ? hasTitles ? {} : {
                    left: 'inherit',
                    right: '5vw'
                }
                : {
                    position: 'relative',
                    marginTop: width < Constants.LayoutBreakpoints.desktop
                        ? 0
                        : '5rem',
                    width: width < Constants.LayoutBreakpoints.desktop
                        ? '100%'
                        : '40%',
                    left: 0,
                    ...(width > Constants.LayoutBreakpoints.mobile && width < Constants.LayoutBreakpoints.desktop ? {
                        marginBottom: '1.5rem'
                    } : {}),
                    ...(width >= Constants.LayoutBreakpoints.desktop
                            ? {
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            } : {}
                    )
                }}>
                <div>
                    <p className="icon-items-wrapper-section-title">{title}</p>
                    <p className="icon-items-wrapper-section-subtitle" dangerouslySetInnerHTML={{__html: subtitle}}/>
                </div>
                {layoutHasImage
                || width <= Constants.LayoutBreakpoints.mobile
                || width >= Constants.LayoutBreakpoints.desktop ? (
                    <p className="icon-items-wrapper-section-content-text" style={{
                        width: layoutHasImage || width <= Constants.LayoutBreakpoints.desktop ? '100%' : '40%'
                    }}>{contentText}</p>
                ) : null}
            </div>
            <div className="icon-items-wrapper-section-content">
                {layoutHasImage
                    ? (
                        <ResponsiveImage width={width} imageSet={imageSet} alt={title}/>
                    )
                    : width > Constants.LayoutBreakpoints.mobile
                    && width < Constants.LayoutBreakpoints.desktop ? (
                        <p className="icon-items-wrapper-section-content-text" style={{
                            width: width < Constants.LayoutBreakpoints.tablet ? '100%' : '40%'
                        }}>{contentText}</p>
                    ) : null}
                <div className="icon-items-wrapper-section-content-items" style={{
                    ...(layoutHasImage
                    || (width < Constants.LayoutBreakpoints.desktop)
                        ? {} : {
                            marginTop: 0
                        }),
                    ...(!hasTitles && width > Constants.LayoutBreakpoints.mobile && width < Constants.LayoutBreakpoints.desktop
                        ? {
                            justifyContent: 'flex-start',
                            marginTop: '-7.5rem'
                        } : {})
                }}>
                    {width > Constants.LayoutBreakpoints.mobile && width < Constants.LayoutBreakpoints.desktop
                        ? renderContentItem(firstContentItem, 0)
                        : contentItems.map(renderContentItem)}
                </div>
            </div>
            {width > Constants.LayoutBreakpoints.mobile && width < Constants.LayoutBreakpoints.desktop
                ? (
                    <div className="icon-items-wrapper-section-tablet-items">
                        {contentItems.filter((_, index) => index !== 0).map(renderContentItem)}
                    </div>
                )
                : null}
        </div>
    )
}

export default IconItemsWrapperSection
