import React from 'react';

import HeaderImage from './index';

import Small from '../../../../assets/images/headers/applicants/small.png'
import Big from '../../../../assets/images/headers/applicants/big.png'

const ApplicantsHeaderImage = (props: { width: number }) => (
    <HeaderImage width={props.width} small={Small} big={Big}/>
);

export default ApplicantsHeaderImage
