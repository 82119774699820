import React from 'react';
import './index.css'
import Input, {InputProps} from '../Input';
import StringTools from '../../../utils/StringTools';

type InputRadioProps = InputProps & {
    checked: boolean | undefined,
}

const defaultProps: InputRadioProps = {
    type: 'radio',
    name: '',
    title: '',
    required: false,
    id: undefined,
    className: undefined,
    style: undefined,
    checked: undefined,
    value: undefined,
    extraProps: {}
}

const InputRadio = (props: InputRadioProps) => {
    const {title, required, checked, id} = props;

    return (
        <Input
            {...props}
            title={StringTools.RemoveHTML(title)}
            extraProps={{
                checked
            }}
            extraView={(
                <>
                    <span></span>
                    <label htmlFor={id} dangerouslySetInnerHTML={{__html: title + (required ? '<span>*</span>' : '')}}/>
                </>
            )}
        />
    );
}

InputRadio.defaultProps = defaultProps;

export default InputRadio
