import React, {useEffect, useState} from 'react';
import './index.css'
import FoldingContentContainer from '../../components/elements/FoldingContentContainer';
import FoldingContentItem from '../../types/elements/FoldingContentItem';
import {Link} from 'react-router-dom';

import {useWidth} from '../../components/Layout';
import LoadingRoot from '../../components/Layout/LoadingRoot';
import Constants from '../../Constants';
import ResponsiveImage from '../../components/elements/ResponsiveImage';
import ResponsiveImageSet from '../../types/ResponsiveImageSet';
import InformationContainer from '../../components/elements/InformationContainer';
import InformationContainerItem from '../../types/elements/InformationContainerItem';
import HomeSection2InformationItemType from '../../types/elements/HomeSection2InformationItemType';
import HomeSection2InformationItem from './HomeSection2InformationItem';

import FirstItemImage from '../../assets/images/pages/home/first.png';
import SecondItemImage from '../../assets/images/pages/home/second.png';

import Section2Mobile from '../../assets/images/pages/home/section2/mobile.png';
import Section2Tablet from '../../assets/images/pages/home/section2/tablet.png';
import Section2Desktop from '../../assets/images/pages/home/section2/desktop.png';

import Section3Mobile from '../../assets/images/pages/home/section3/mobile.png';
import Section3Tablet from '../../assets/images/pages/home/section3/tablet.png';
import Section3Desktop from '../../assets/images/pages/home/section3/desktop.png';

import Section4Mobile from '../../assets/images/pages/home/section4/man.png';
import BlogSection from '../../components/sections/Blog';
import SocialMediaSection from '../../components/sections/SocialMediaSection';

const firstItem: FoldingContentItem = {
    topTitle: 'Für',
    title: 'Bewerber',
    content: 'Wir haben es uns zur Aufgabe gemacht, dem Pflegepersonal die Wertschätzung entgegenzubringen, die es verdient hat. Dafür sollen die Anliegen der Fachkräfte stets Priorität haben – denn wer seine Energie dafür aufbringt, anderen zu helfen, dem soll auch geholfen werden.',
    backgroundImage: `url(${FirstItemImage})`,
    buttonTo: '/bewerber'
}
const secondItem: FoldingContentItem = {
    topTitle: 'Für',
    title: 'Einrichtungen',
    content: 'Weil das Gesundheitswesen ein zentraler Bestandteil unserer gesamten Gesellschaft ist, ist es uns umso wichtiger, Ihrer Einrichtung verlässliches und kompetentes Fachpersonal zur Verfügung zu stellen – und zwar dann, wenn Sie es brauchen.',
    backgroundImage: `url(${SecondItemImage})`,
    buttonTo: '/bewerber'
}

const section2ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section2Mobile,
        style: {
            left: 0,
            height: 'auto',
            width: '100vw'
        }
    },
    tablet: {
        src: Section2Mobile,
        style: {
            left: 0,
            height: 'auto',
            width: '100vw'
        }
    },
    desktop: {
        src: Section2Tablet,
        style: {
            left: 0,
            height: 'auto',
            width: '100%'
        }
    },
    huge: {
        src: Section2Desktop,
        style: {
            right: 0,
            height: 'auto',
            width: '60vw'
        }
    }
}

const section2InformationItems: HomeSection2InformationItemType[] = [
    {
        title: 'Intensivmedizin',
        content: 'Betreuung schwer kranker Menschen in lebensbedrohlichen Zuständen...',
        to: 'https://jobs.komedis.de/jobs/?search_category=intensivmedizin'
    },
    {
        title: 'Operationsdienst',
        content: 'Spezialisierung auf chirurgische Eingriffe und Verfahren, um... ',
        to: 'https://jobs.komedis.de/jobs/?search_category=operationsdienst-op-pflege'
    },
    {
        title: 'MTA | Radiologie',
        content: 'Expertise in der medizinischen Bildgebung und Verwendung von...',
        to: 'https://jobs.komedis.de/jobs/?search_category=mtra-radiologie'
    },
    {
        title: 'Geburtshilfe',
        content: 'Spezialisierung auf die Betreuung von schwangeren Menschen und die...',
        to: 'https://jobs.komedis.de/jobs/?search_category=geburtshilfe-kreisssaal'
    }
];

const section3ImageSet: ResponsiveImageSet = {
    mobile: {
        src: Section3Mobile,
        style: {
            height: 'fit-content',
            width: '90vw'
        }
    },
    tablet: {
        src: Section3Mobile,
        style: {
            height: 'fit-content',
            width: '90vw'
        }
    },
    desktop: {
        src: Section3Tablet,
        style: {
            height: 'fit-content',
            width: '45vw'
        }
    },
    huge: {
        src: Section3Desktop,
        style: {
            height: 'fit-content',
            width: '45vw'
        }
    }
}

const HomePage = () => {
    const {width} = useWidth();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, []);

    const section2InfoContainerItem: InformationContainerItem = {
        title: 'Immer bestens aufgestellt',
        subtitle: 'Unsere <span>marktführenden</span> Fokus'
            + (width >= Constants.LayoutBreakpoints.tablet && width < Constants.LayoutBreakpoints.desktop ? '-<br/>' : '')
            + 'bereiche',
        content: 'Ob Gesundheits- und Krankenpfleger auf Intensivstationen, in allgemeinen Pflegebereichen oder in aufkommenden Nischen wie der Palliativmedizin - unser KOMEDIS-Team deckt ein breites Spektrum im Gesundheitswesen ab.'
    };

    const [activeSection2InformationItem, setActiveSection2InformationItem] = useState<HomeSection2InformationItemType | null>(null);

    const renderSection2InformationItems = (item: HomeSection2InformationItemType, index: number) => {
        return (
            <HomeSection2InformationItem
                key={'home_section2_information_item_' + index}
                item={item}
                active={activeSection2InformationItem !== null && activeSection2InformationItem.title === item.title}
                setActive={active => setActiveSection2InformationItem(active ? item : null)}
            />
        );
    }

    return loaded ? (
        <div className="pageContent" id="homePage">
            <div className="section" id="section1">
                <div className="section1-part">
                    <p className="title">Zeitarbeit neu gedacht</p>
                    <p className="subtitle">
                        Wir <span>bringen</span> Medizinpersonal & Einrichtungen <span>zusammen</span>
                    </p>
                    <p className="content-text">
                        KOMEDIS fungiert als Bindeglied zwischen Institutionen und Arbeitenden im Gesundheitswesen. Wir
                        überlassen unsere Mitarbeiter dorthin, wo Hilfe gebraucht, geschätzt und fair entlohnt wird.
                    </p>
                </div>
                <FoldingContentContainer
                    className="section1-part"
                    width={width}
                    firstItem={firstItem}
                    secondItem={secondItem}
                />
            </div>
            <div id="section2">
                <div id="section2InformationContainerWrapper" className="full-width" style={{
                    position: 'relative',
                    height: 'fit-content',
                    display: 'flex',
                    flexDirection: width >= Constants.LayoutBreakpoints.tablet ? 'row' : 'column'
                }}>
                    <ResponsiveImage width={width} imageSet={section2ImageSet} alt="section2"/>
                    <InformationContainer item={section2InfoContainerItem} width={width}/>
                </div>
                <div id="section2InformationItems">
                    {section2InformationItems.map(renderSection2InformationItems)}
                </div>
            </div>
            <div className="section" id="section3">
                <ResponsiveImage width={width} imageSet={section3ImageSet} alt="section3"/>
                <div className="section3-part">
                    <p className="title">Arbeiten neu gedacht</p>
                    <p className="subtitle">
                        Zeitarbeit auf einem <span>neuen Level</span>
                    </p>
                    <p className="content-text">
                        KOMEDIS geht über die herkömmliche Überlassung von Pflegepersonal hinaus. Wir sind ein Partner
                        für Veränderung, denn unsere Dienstleistungen zielen darauf ab, die Bedingungen für Pflegekräfte
                        grundlegend zu verbessern, um eine angemessene Work-Life-Balance zu ermöglichen. Bei uns dreht
                        sich alles darum, Pflegekräfte von Druck und Sorgen zu entlasten, damit sie sich voll und ganz
                        auf die Essenz ihrer Arbeit und den moralischen Aspekt ihrer Berufung konzentrieren können.
                    </p>
                </div>
            </div>
            <div id="section4">
                <div id="section4-content" className="section4-part">
                    <p id="section4-title">Die Zusammenarbeit mit KOMEDIS</p>
                    <p id="section4-subtitle">Bei <span>Fragen</span> sind wir gerne für dich da.</p>
                    <Link to={'/' + Constants.RoutesMeta.contact.path}>
                        Jetzt kontaktieren
                    </Link>
                </div>
                {width < Constants.LayoutBreakpoints.tablet ? (
                    <div className="section4-part">
                        <img src={Section4Mobile} style={{
                            height: 'auto',
                            width: '70vw'
                        }} alt="Die Zusammenarbeit mit KOMEDIS"/>
                    </div>
                ) : null}
            </div>
            <BlogSection width={width} />
            <SocialMediaSection width={width}/>
        </div>
    ) : <LoadingRoot isLoading={true} inline={true}/>
}

export default HomePage;
