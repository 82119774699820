import React, {useLayoutEffect} from 'react';

const RedirectFaker = (props: {
    to: string
}) => {
    document.body.innerHTML = '';
    useLayoutEffect(() => {
        window.location.href = props.to
    }, []);
    return <div></div>
}

export default RedirectFaker
