import React, {useEffect, useState} from 'react';
import './index.css'
import {Link} from 'react-router-dom';
import Constants from '../../../Constants';
import API from '../../../utils/API';
import BlogArticle from '../../../types/entities/BlogArticle';
import LoadingRoot from '../../Layout/LoadingRoot';
import Slider from '../../elements/Slider';
import BlogItem from './BlogItem';
import BlogCategory from '../../../types/entities/BlogCategory';

type BlogSectionProps = {
    width: number,
    category: BlogCategory | null,
    showInfoBlock: boolean,
    headerTitle: string | null,
    skipArticle?: BlogArticle | null,
};

const defaultProps: BlogSectionProps = {
    width: window.innerWidth,
    category: null,
    showInfoBlock: true,
    headerTitle: null,
    skipArticle: null,
}

const BlogSection = (props: BlogSectionProps) => {
    const {width, category, showInfoBlock, headerTitle, skipArticle} = props;

    const [blogPosts, setBlogPosts] = useState<BlogArticle[] | null>(null);
    const [page, setPage] = useState(1);
    const [morePostsAvailable, setMorePostsAvailable] = useState(false);

    const loadBlogPosts = (previousBlogPosts: BlogArticle[] = []) => {
        API.getBlogArticles(page, category, 3, skipArticle)
            .then(response => Constants.executeWithoutLoader(() => {
                setBlogPosts([...previousBlogPosts, ...response.data.blogArticles])
                setMorePostsAvailable(response.data.lastPage > page)
            }))
            .catch(e => Constants.handleException(e))
    };

    useEffect(() => loadBlogPosts(), [category]);

    useEffect(() => loadBlogPosts(blogPosts ? blogPosts : []), [page])

    const renderBlogArticleItem = (article: BlogArticle, index: number) => {
        return (
            <BlogItem key={'blog_article_item_' + index} article={article}/>
        );
    }

    return blogPosts === null ?
        (<LoadingRoot isLoading={true} inline={true}/>)
        : blogPosts.length < 1 ? null : (
            <div id="blogSection" className="full-width">
                {headerTitle ? (
                    <h1
                        className="section-subtitle"
                        style={width >= Constants.LayoutBreakpoints.tablet ? {} : {
                            marginLeft: '2.5rem',
                            marginTop: '2.5rem',
                            marginBottom: '2.5rem',
                        }}
                    >
                        {headerTitle}
                    </h1>
                ) : null}
                {showInfoBlock ? (
                    <div id="blogSectionInfoItems">
                        <div className="blog-section-info-item" style={{
                            width: width < Constants.LayoutBreakpoints.tablet
                                ? '100%'
                                : '45%'
                        }}>
                            <p id="blogSectionTitle">Bestens informiert</p>
                            <p id="blogSectionSubtitle">Spannende <span>Insights</span> der Branche</p>
                        </div>
                        <div id="blogSectionInfoItemCTA" className="blog-section-info-item" style={{
                            width: width < Constants.LayoutBreakpoints.tablet
                                ? '100%'
                                : '55%'
                        }}>
                            <p id="blogSectionCTATitle">Inspiration, Information und Tipps rund um die Welt der Pflege
                                und
                                Medizin!</p>
                            <Link id="blogSectionCTAButton" to={'/' + Constants.RoutesMeta.blog.path}>
                                Zu allen Beiträge
                            </Link>
                        </div>
                    </div>
                ) : null}
                <div id="blogItemsContainer">
                    {width < Constants.LayoutBreakpoints.desktop ? (
                        <Slider width={width} stepPerNavigate={width < Constants.LayoutBreakpoints.tablet ? 1 : 2}>
                            {blogPosts.map(renderBlogArticleItem)}
                        </Slider>
                    ) : blogPosts.map(renderBlogArticleItem)}
                </div>
                {
                    morePostsAvailable
                        ? (
                            <button id="loadMore" type="button" onClick={() => setPage(page + 1)}>
                                + Weitere Beiträge
                            </button>
                        )
                        : null
                }
            </div>
        )
}

BlogSection.defaultProps = defaultProps;

export default BlogSection;
