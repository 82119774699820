import React from 'react';

import ResponsiveImage from '../../../elements/ResponsiveImage';

const style = {
    width: '100vw',
    height: 'auto',
};

const HeaderImage = (props: { width: number, small: string, big: string }) => {
    const {width, small, big} = props;
    const headerImageSet = {
        mobile: {
            src: small,
            style
        },
        tablet: {
            src: small,
            style
        },
        desktop: {
            src: small,
            style
        },
        huge: {
            src: big,
            style
        }
    }
    return (
        <ResponsiveImage width={width} alt="header" imageSet={headerImageSet}/>
    )
}

export default HeaderImage
