import React, {useState} from 'react';
import './index.css'

import ModalVideo from 'react-modal-video';

import Video, {VideoType} from '../../../types/Video';
import StringTools from '../../../utils/StringTools';
import VideoEmbed from '../../../utils/VideoEmbed';

const VideoPlayer = (props: {
    video: Video,
    showBackdrop: boolean,
    showPlayButtonBackground?: boolean
}) => {

    const {video, showBackdrop, showPlayButtonBackground} = props;

    const [modalOpened, setModalOpened] = useState(false);

    const videoPlayerId = 'video_' + StringTools.MD5(video.url);
    const videoEmbed = new VideoEmbed(video);

    const videoProps = () => {
        switch (video.type) {
            case VideoType.youtube: {
                return {
                    channel: 'youtube',
                    videoId: videoEmbed.getVideoId(),
                    youtube: {mute: 0, autoplay: 0}
                }
            }
            case VideoType.file: {
                return {
                    channel: 'custom',
                    url: videoEmbed.getEmbedUrl()
                }
            }
        }
    }

    return (
        <div id={videoPlayerId} className="video-player">
            <div className="video-player-modal" style={{
                display: modalOpened ? '' : 'none'
            }}>
                {/* @ts-expect-error ignore type hint */}
                <ModalVideo
                    {...videoProps()}
                    isOpen={modalOpened}
                    allowFullScreen={true}
                    onClose={() => setModalOpened(false)}
                />
            </div>
            {showBackdrop ? <div className="video-player-background"/> : null}
            <div
                className="video-player-preview"
                style={{
                    backgroundImage: 'url(' + video.previewImage + ')',
                    marginTop: showBackdrop ? '' : 0
                }}
            >
                {showPlayButtonBackground ? (
                    <div className="video-player-play-button-wrapper">
                        <button
                            className="video-player-play-button"
                            onClick={() => setModalOpened(true)}
                        />
                    </div>
                ) : (
                    <button
                        className="video-player-play-button"
                        onClick={() => setModalOpened(true)}
                    />
                )}
            </div>
        </div>
    )
}

export default VideoPlayer
