import React from 'react';
import NavigationItemProps from '../../../../types/elements/NavigationItem';
import {Link} from 'react-router-dom';

type NavigationItemElementProps = {
    info: NavigationItemProps,
    active: boolean,
    onClick?: () => void
}

const defaultProps: NavigationItemElementProps = {
    info: {title: ''},
    active: false,
    onClick: () => {}
}

const NavigationItem = (props: NavigationItemElementProps) => {
    const {info, active, onClick} = props;
    return (
        <li>
            <Link
                className={active ? 'active' : ''}
                style={info.font
                    ? {
                        fontFamily: info.font,
                        fontSize: 20
                    }
                    : {}
                }
                to={info.url ?? '/' + info.route!.path}
                onClick={onClick}
            >
                {info.title}
            </Link>
        </li>
    )
}

NavigationItem.defaultProps = defaultProps;

export default NavigationItem
