import React from 'react';

import HeaderImage from './index';

import Small from '../../../../assets/images/headers/home/small.png'
import Big from '../../../../assets/images/headers/home/big.png'

const HomeHeaderImage = (props: { width: number }) => (
    <HeaderImage width={props.width} small={Small} big={Big}/>
);

export default HomeHeaderImage
