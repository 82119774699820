
export enum VideoType {
    youtube = 'youtube',
    file = 'file'
}

type Video = {
    url: string;
    type: typeof VideoType[VideoType];
    previewImage: string;
}
export default Video
